@import 'styles/colors';

.container {
  max-width: 132px;
  max-height: 62px;
  background-color: $white_color;
  border-radius: 8px;
  padding: 12px;

  .titleWrapper {
    display: flex;
    gap: 5px;
    align-items: center;

    .title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }
  }

  .subtitle {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
  }
}
