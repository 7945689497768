@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-grow: 1;
  margin: 24px 20px 0;
  max-width: $max-content-width;
  align-items: center;

  .cardContainer {
    width: 100%;
    background-size: auto 100%;
    height: 78px;
    background-repeat: no-repeat;
    background-position: top right;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-image: url('../../../assets/bg_particles_yellow.png');

    .label {
      margin-left: 20px;
      color: $white_color;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.2px;
    }

    .particles {
      position: absolute;
      right: 70px;
    }

    &.giftCard {
      background-image: url('../../../assets/onboarding/gift.svg');
      background-color: #ec8847;
      margin-bottom: 10px;
    }
  }

  .totalWrapper {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #f4f1e8;
    padding: 0;
    margin-top: -10px;
    width: 100%;

    .priceWrapper {
      display: flex;
      gap: 10px;
    }

    .total {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-self: center;
    }

    .discount {
      display: flex;
      width: 50px;
      height: 27px;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      background: #ef5350;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 590;
      line-height: 24px;
      margin-top: 16px;
    }

    .oldPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a6a4a9;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      text-decoration: line-through;
      text-transform: uppercase;
    }

    .newPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  h4 {
    font-size: 28px;
    line-height: 36px;
    text-align: left;
    font-weight: 700;
    color: $dark_color;
    margin-bottom: 12px;
    margin-top: 0px;
    align-self: start;

    .accent {
      color: #b250ff;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: rgba($dark_color, 0.64);
    gap: 8px;
  }

  .stickyContainer {
    display: flex;
    gap: 16px;
    align-self: center;
  }

  .greenButton {
    background: $active_green_color;
    color: $white_color;
    max-height: 48px;
  }

  .grayButton {
    background: none;
    background-color: #f4f1e8;
    color: $dark_color;
    cursor: pointer;
    max-height: 48px;
  }

  .button {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .note {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    color: rgba($dark_color, 0.4);
    margin: 0 20px;
  }
}
