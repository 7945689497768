@import 'styles/colors';

.disclaimer {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15px;
  margin: 15px 0 15px;
  color: rgba($dark_color, 0.5);

  a {
    color: rgba($dark_color, 0.5);
  }

  &.topMargin {
    margin: 8px 0 15px;
  }

  &.darkTheme {
    color: rgba($white_color, 0.5);

    a {
      color: rgba($white_color, 0.5);
    }
  }
}
