@import 'styles/colors';

.bottomInputContainer {
  display: flex;
  padding: 10px 24px;

  .checkbox {
    display: flex;
    cursor: pointer;

    &.active {
      svg {
        path {
          &:first-child {
            fill: $active_green_color;
          }
          &:last-child {
            fill: white;
          }
        }
      }
    }
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: rgba($dark_color, 0.5);
    padding-left: 10px;
  }
}
