@import 'styles/colors';

.checkList {
  list-style-image: url(../../../../../assets/icon_check.svg);
  line-height: 18px;
  padding: 0;
  letter-spacing: 0.17px;
  margin: 0px 20px;

  li {
    padding-left: 10px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.applePayBtn {
  cursor: pointer;
  background-color: #000000 !important;
  background-image: none !important;
  border-radius: 4px !important;

  &::before {
    content: '';
    height: 18px;
    width: 20px;
    margin-left: 5px;
    background: url(../../../../../assets/icon_btn_apple_pay.svg) no-repeat;
  }
}

.paypalBtn {
  cursor: pointer;

  &::after {
    content: '';
    height: 18px;
    width: 20px;
    margin-left: 5px;
    background: url(../../../../../assets/ic_lock_white.svg) no-repeat;
  }
}

.title {
  font-size: 24px;
  color: $dark_color;
  margin: 0;
  margin-top: 20px;
}

.description {
  font-weight: 600;
  color: $dark_color;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
}
