@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 20px 0;
  flex: 1;

  .title {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
    color: $dark_color;
    margin-bottom: 24px;
  }

  .note {
    background-color: #E9DFF2;
    border-radius: 16px;
    text-align: center;
    color: #090110;
    padding: 20px 16px;
  }

  .content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .stickyContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .button {
        background: #6DBC81;
        color: $white_color;

        &.secondary {
          margin: 0;
          background: $white_color;
          color: $dark_color;
        }
      }
    }
  }
  
  &.maxWidth {
    max-width: 480px;
  }
}