@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-grow: 1;
  margin: 0 20px;
  width: $max-content-width;
  max-width: 100%;
  position: relative;

  ul {
    margin: 0px;
    padding: 0 0 0 12px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    opacity: 0.8;
  }
   
  h4 {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
    color: $dark_color;
    position: absolute;
    top: 50px;
    animation: smooth-appear-v1 0.5s ease forwards;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $dark_color;
    max-width: $max-content-width;
    position: absolute;
    top: 150px;
    animation: smooth-appear-v2 0.5s ease forwards;
    width: calc(100% - 40px);
  }

  .button {
    margin-bottom: 20px;
    width: calc(100% - 40px);
  }

  .stickyContainer {
    opacity: 0;
    animation: smooth-appear-v3 0.5s ease forwards;
    animation-delay: 900ms;
  }
}

@keyframes smooth-appear-v1 {
  to {
    top: 0px;
    opacity: 1;
  }
}

@keyframes smooth-appear-v2 {
  to {
    top: 90px;
    opacity: 1;
  }
}

@keyframes smooth-appear-v3 {
  to {
    bottom: 0;
    opacity: 1;
  }
}