@import 'styles/colors';

.planCard {
  border-radius: 12.4596px;
  background: rgba(255, 255, 255, 0.15);
  flex: 1;
  color: white;
  height: 94px;
  position: relative;
  margin-bottom: 14px;

  svg {
    position: absolute;
    top: 6px;
    right: 6px;
    path {
      fill: #b350ff;
    }
  }

  .title {
    font-size: 20px;
    line-height: 119.8%;
    text-align: center;
    letter-spacing: 0.155745px;
    margin-bottom: 7px;
    font-weight: 500;
  }

  .price {
    margin-top: 0;
    opacity: 0.5;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.519149px;
    font-weight: 500;
  }

  &.selected {
    background-color: white;
    color: $dark_purple_color;

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: white;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      bottom: -16px;
      left: 50%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 2px;
    }
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}
