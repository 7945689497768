@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/colors';

.item {
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
  @include noSelect;
  border-bottom: 1px solid rgba(#a6a4a9, 0.2);

  &:last-child {
    border-bottom: none;
  }

  .radio {
    width: 17px;
    height: 17px;
    background-position: center center;
    background-size: cover;

    &.checkedRadio {
      background-image: url('../../../../../assets/radio_checked.svg');
    }

    &.unCheckedRadio {
      background-image: url('../../../../../assets/radio_no_checked.svg');
    }
  }

  .titleContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
    cursor: pointer;

    .titleWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .title {
      color: $dark_color;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }

    .radioButton {
      margin-right: 10px;
      cursor: pointer;
    }

    svg {
      &:hover,
      :active {
        opacity: 0.7;
      }
    }
  }

  .content {
    color: rgba($dark_color, 0.64);
    transition: max-height 0.3s ease;
    text-align: left;
    overflow: hidden;
    margin: 0 16px;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}
