@import 'styles/colors';
@import 'styles/mixins';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 18px;
  box-sizing: border-box;

  &.speed {
    .options {
      .option {
        transition: opacity 150ms ease-in;
      }
    }
  }

  .button {
    max-width: 100%;
    height: 52px;
    min-height: 52px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $dark_color;
    margin: 0 0 11px;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: rgba($dark_color, 0.5);
    font-weight: normal;
    margin: 0 0 28px;
  }

  .options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 11px;
    margin-bottom: 10px;

    .option {
      @include noSelect;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 20px;
      color: rgba($dark_color, 0.5);
      cursor: pointer;
      opacity: 1;
      transition: opacity 250ms ease-in;
      background: $light_cream_color
        url(../../../assets/ic_option_checkmark_empty.svg) no-repeat
        94% 10px;

      &.enter {
        opacity: 1;
      }

      &.leave {
        opacity: 0;
      }

      &.active {
        transform: scale(0.98);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
        background: $active_green_color
          url(../../../assets/ic_option_checkmark_checked.svg)
          no-repeat 94% 10px;

        .optionTitle {
          color: $white_color;
        }

        svg {
          path {
            stroke: $white_color;
          }

          path[custom-rule='true'] {
            fill: $white_color;
          }
        }
      }

      .optionTitle {
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
        font-weight: 600;
        color: $dark_color;
        text-align: center;
      }
    }
  }
}
