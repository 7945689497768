@import 'styles/colors';

.container {
  width: 100%;

  .textWrapper {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: rgba($dark_color, 0.7);
    margin: 12px 0 10px;
  }
}

.background {
  width: 100%;
  height: 15px;
  border-radius: 18px;
  overflow: hidden;

  .progress {
    opacity: 1;
    height: 100%;
    transition: 0.2s width linear;
  }
}
