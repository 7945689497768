@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-grow: 1;
  margin: 24px 20px 20px;
  max-width: $max-content-width;
  align-items: center;

  .image {
    margin: 30px 0 20px;
    align-self: flex-end;
  }

  .content {
    .title {
      font-size: 28px;
      line-height: 36px;
      text-align: left;
      font-weight: 700;
      color: $dark_color;
      margin-bottom: 20px;
      align-self: start;
    }

    .accent {
      color: #b350ff;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: rgba($dark_color, 0.64);
    }
  }

  .cardContainer {
    width: 100%;
    background-size: auto 100%;
    height: 78px;
    background-repeat: no-repeat;
    background-position: top right;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .label {
      margin-left: 20px;
      color: $white_color;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.2px;
    }

    .particles {
      position: absolute;
      right: 70px;
    }

    .time {
      font-size: 28px;
      line-height: 22px;
      font-weight: 700;
      color: $white_color;
      margin-left: 20px;
      margin-bottom: 8px;
    }

    &.giftCard {
      background-image: url('../../../assets/onboarding/gift.svg');
      background-color: #ec8847;
      margin-bottom: 10px;
    }
  }

  .totalWrapper {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #f4f1e8;
    padding: 0;
    margin-top: -10px;
    width: 100%;

    .priceWrapper {
      display: flex;
      gap: 10px;
    }

    .total {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-self: center;
    }

    .discount {
      display: flex;
      width: 50px;
      height: 27px;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      background: #ef5350;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 590;
      line-height: 24px;
      margin-top: 16px;
    }

    .oldPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a6a4a9;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      text-decoration: line-through;
      text-transform: uppercase;
    }

    .newPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .button {
      margin: 0;
      padding: 0;

      &.secondary {
        margin: 0;
        background: $white_color;
        color: $dark_color;
      }
    }
  }

  .stickyContainer {
    display: flex;
    gap: 8px;
    align-self: center;
  }

  .greenButton {
    background: $active_green_color;
    color: $white_color;
  }

  .grayButton {
    background: none;
    background-color: #f4f1e8;
    color: $dark_color;
    cursor: pointer;
  }

  .button {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .note {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    color: rgba($dark_color, 0.4);
    margin: 0 20px;
  }

  .planContent {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      display: flex;
      justify-content: space-between;
      color: $dark_color;
      margin-bottom: 10px;
      margin-top: 32px;
      font-size: 22px;
      line-height: 18px;
      font-weight: 600;

      span {
        color: rgba($dark_color, 0.64);
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .field {
      display: flex;
      justify-content: space-between;
      margin: 6px 0;
      color: $dark_color;

      &.red {
        color: #ef5350;
        font-weight: 600;
      }

      .label {
        font-size: 16px;
        line-height: 24px;

        &.normal {
          font-weight: 400;
        }
      }

      &.total {
        margin: 16px 0 0;
        line-height: 26px;
        font-weight: 700;
        color: $dark_color;

        .label {
          font-size: 16px;
          line-height: 26px;
          font-weight: 600;
        }
      }

      &.discount {
        margin: 4px 0;
      }
    }
  }
}
