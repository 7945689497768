@import 'styles/mixins';
@import 'styles/colors';

.buttonContainer {
  @include noSelect;
  background-color: rgba(178, 80, 255, 0.5);
  border-radius: 50px;
  color: white;
  width: 100%;
  height: 52px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  pointer-events: auto;

  &:active {
    &.ready {
      transform: scale(0.98);
      box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .progress {
    height: 52px;
    background: $gradient fixed, fixed;
    width: 100%;
    position: absolute;
    transition: 0.2s width linear;
  }

  .text {
    display: flex;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity ease 0.5s, top ease 0.5s;

    &.switching {
      opacity: 0;
      top: -10px;
    }

    &.updating {
      @include animation('fadeIn ease .5s');
    }

    @include keyframes(fadeIn) {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
