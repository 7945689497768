@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  touch-action: none;

  .progressText {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #352e2e;
    white-space: pre;
    margin-bottom: 10px;
  }

  h1, h3 {
    margin: 0;
  }

  .progressButton {
    margin-top: 20px;
  }

  .image {
    width: 100%;
    height: 65vh;
    object-position: bottom;
    margin-top: -40px;
  }

  .content {
    max-width: $max-content-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 2;
    position: fixed;
    bottom: 0;

    .title {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      font-weight: 700;
      margin: 0 12px 12px;

      .accent {
        color: $secondary_accent_color;
      }
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
      color: rgba($dark_color, 0.64);
      text-align: center;
      margin-bottom: 10px;
      font-weight: 400;
      white-space: pre-wrap;

      span::after,
      span::before {
        content: ' ';
      }

      .accent {
        color: $dark_color;
        font-weight: 600;
      }
    }

    .button {
      min-height: 52px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    .image {
      height: 60vh;
      margin-top: -20px;
    }

    .content {
      .title {
        font-size: 28px;
        line-height: 34px;
        white-space: pre-wrap;
      }
      .subtitle {
        font-size: 14px;
        line-height: 22px;
        white-space: unset;
      }
    }
  }
}

@media (min-width: $breakpoint-desktops) {
  .wrapper {
    .image {
      object-fit: contain;
    }
  }
}

@media (max-height: 630px) {
  .wrapper {
    .image {
      object-fit: contain;
      transform: scale(0.9);
      margin-top: -60px;
    }

    .content {
      .title {
        font-size: 26px;
        line-height: 30px;
        white-space: pre-wrap;
      }
    }
  }
}
