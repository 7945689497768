@import 'styles/colors';

.sticky-container {
  padding: 12px;
  background-color: $accent_red;
  border-radius: 13px;
}

.sticky-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: rgba($accent_red, 0.9);
  z-index: 1000;
  margin-bottom: 0px;
  width: 100%;
  max-width: 440px;
  box-sizing: border-box;
  border-radius: 0px;
}

.active-timer {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: $white_color;
}

.timer-box {
  text-align: center;
}

@media (max-width: 450px) {
  .sticky-bottom {
    margin-left: -20px;
  }
}
