@import 'styles/colors';

.review {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .review-text {
    margin: 15px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $dark_color;
  }

  .review-header {
    display: flex;
    margin-bottom: 15px;

    .review-author {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 12px;
    }

    .review-avatar {
      height: 44px;
      width: 44px;
      border-radius: 44px;
    }

    .review-title {
      font-size: 14px;
      line-height: 20px;
      color: rgba($dark_color, 0.6);
    }

    .stars {
      align-items: center;
      display: flex;
      font-size: 14px;
      line-height: 20px;
      color: rgba($dark_color, 0.6);

      .star-container {
        display: inherit;
        position: relative;

        .cropped-star {
          display: inherit;
          overflow: hidden;
          position: absolute;
        }
      }

      .star {
        height: 19px;
        width: 19px;

        &:not(:last-child) {
          margin-right: 1px;
        }
      }

      img {
        height: 15px;

        &:not(:last-child) {
          margin-right: 1px;
        }
      }

      span {
        margin-left: 10px;
      }
    }
  }
}
