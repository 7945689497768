@import 'styles/colors';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;

  &.speed {
    .imageWrapper {
      transition: unset;
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $dark_color;
    margin: 0;
  }

  .description {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: rgba($dark_color, 0.5);
    margin: 10px 0;
  }

  .imageWrapper {
    flex: 1;
    margin: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 250ms ease-in;

    &.enter {
      opacity: 1;
    }

    &.leave {
      opacity: 0;
    }

    .image {
      object-fit: contain;
      max-width: 100%;
      max-height: 50vh;
    }
  }

  .optionsContainer {
    display: flex;
    margin-bottom: 10px;

    .option {
      background: $light_cream_color none;
      border-radius: 40px;
      height: 52px;
      min-height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $dark_color;
      max-width: initial;
      margin-top: 0;
      cursor: pointer;
      transition: 150ms all;

      &:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      &.multiple {
        background: $light_cream_color
          url(../../../assets/ic_option_checkmark_empty.svg)
          no-repeat right 15px center;

        &.active {
          background: $active_green_color
            url(../../../assets/ic_option_checkmark_checked.svg)
            no-repeat right 15px center;
        }
      }

      &.active {
        background: $active_green_color none;
        color: $white_color;
      }
    }
  }

  .button {
    max-width: 100%;
  }
}
