@import 'styles/color_themes';
@import 'styles/colors';

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 400;
  src: url('fonts/EuclidCircularA_regular.otf') format('opentype');
}

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 500;
  src: url('fonts/EuclidCircularA_medium.otf') format('opentype');
}

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 600;
  src: url('fonts/EuclidCircularA_semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 700;
  src: url('fonts/EuclidCircularA_bold.otf') format('opentype');
}

@font-face {
  font-family: 'Arial';
  font-weight: 500;
  src: url('fonts/Arial_medium.ttf') format('opentype');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Euclid Circular A';
  font-weight: 400;
  background-color: $white_color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  letter-spacing: 0.2px;

  &.modal-open {
    overflow: hidden;
    touch-action: none;
  }

  &.scroll-hidden {
    overflow: hidden;
  }
}

@include dark {
  body {
    background-color: #140023;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
