@import 'styles/colors';
@import 'styles/variables';

.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  padding: 0 20px;
  box-sizing: border-box;

  .image {
    position: absolute;
    width: 100%;
  }

  .title {
    justify-self: flex-start;
    color: $dark_color;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.4px;
    transition: opacity 0.5s ease;
    opacity: 1;
    margin-bottom: 12px;

    &.transitionIn {
      opacity: 1;
    }

    &.transitionOut {
      opacity: 0;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    color: rgba($dark_color, 0.64);
    margin-top: 0;
  }

  .status {
    color: $white_color;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  .spinner {
    $size: 36px;
    $speed: 1.5s;
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: 50%;
    animation: circ-anim $speed linear infinite;
    border: 3px solid #d295ff;
    border-right-color: transparent;
    margin-bottom: 17px;
  }

  @keyframes circ-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
