@import 'styles/colors';

.container {
  margin-top: 30px;

  .title {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
  }

  .tableContainer {
    border: 16px solid $light_cream_color;
    border-radius: 16px;
    margin-top: 24px;

    .table {
      width: 100%;
      max-width: 600px;
      background-color: $light_cream_color;
      border-collapse: collapse;

      tr {
        th {
          &:last-child {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }

          &.coach {
            text-align: center;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            color: rgba($dark_color, 0.64);
            padding-top: 7px;
          }

          &.loona {
            display: flex;
            justify-content: center;
            text-align: center;
            background-color: $light_violet_color;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            color: $white_color;

            .label {
              max-width: 50px;
              background-color: $main_accent_color;
              border-radius: 5px;
              padding: 4px;
              margin-top: 7px;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 2px solid rgba($dark_color, 0.08);

          &:last-child {
            border-bottom: none;

            td {
              border-bottom-left-radius: 16px;
              border-bottom-right-radius: 16px;
            }
          }

          td {
            font-weight: 500;
            padding: 10px;

            &.coach,
            &.loona {
              text-align: center;
              color: rgba($dark_color, 0.64);

              .smallText {
                display: block;
                font-size: 12px;
                margin-top: -4px;
              }
            }

            &:first-child {
              font-size: 16px;
              font-weight: 500;
              color: $dark_color;
              line-height: 20px;
              padding-left: 0;
            }

            &:last-child {
              background: $light_violet_color;
            }
          }
        }
      }
    }
  }
}
