@import 'styles/colors';

:global {
  .recurly-element-card {
    font-size: 16px;
    height: 32px;
    margin: 5px 0;
    letter-spacing: 0.2px;
    background-color: $white_color;
    border-radius: 8px;
    border: 1px solid $white_color;
    caret-color: rgba($dark_color, 0.64);
  }
}

.label {
  font-size: 8px;
  color: rgba(9, 1, 16, 0.64);
  font-weight: 500;
  line-height: 12px;
  white-space: nowrap;

  &.cvc {
    margin-right: 4px;
  }
}

.formError {
  font-size: 8px;
  line-height: 10px;
  color: #ef5350;
  margin-left: 2px;
}
