@import 'styles/colors';
@import 'styles/variables';

$image_count: 11;
$image_duration: 3s;
$cross_fade_duration: 1s;

@for $i from 0 through $image_count - 1 {
  .loaderImage-#{$i} {
    width: $max-content-width;
    height: 100%;
    position: absolute;
    top: 0;
    transition: opacity 250ms ease-in;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    z-index: -1;
    opacity: 1;

    &.default {
      background-image: url('../../../assets/onboarding/loader/#{$i + 1}.jpg');
    }

    &.v2 {
      background-size: contain;
      background-image: url('../../../assets/onboarding/loader/v2/#{$i + 1}.png');
    }

    &.transitionIn {
      opacity: 1;
    }

    &.transitionOut {
      opacity: 0;
    }
  }
}

.container {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  background-color: $dark_color;

  &.v2 {
    background-color: $white_color;

    .content {
      .title {
        max-width: $max-content-width;
        color: $dark_color;
        margin: 0 20px;
      }

      .status {
        color: $dark_color;
      }
    }
  }

  &.enter {
    opacity: 1;
  }

  &.leave {
    opacity: 0;
  }

  .content {
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 20px;

    .title {
      max-width: $max-content-width;
      margin: 0 20px;
      justify-self: flex-start;
      color: $white_color;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.4px;
      transition: opacity 0.5s ease;
      opacity: 1;

      &.transitionIn {
        opacity: 1;
      }

      &.transitionOut {
        opacity: 0;
      }
    }

    .status {
      color: $white_color;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      white-space: pre;
      max-width: 187px;
    }

    .spinner {
      $size: 30px;
      $speed: 1.5s;
      display: inline-block;
      width: $size;
      height: $size;
      min-height: $size;
      border-radius: 50%;
      animation: circ-anim $speed linear infinite;
      border: 3px solid #d295ff;
      border-right-color: transparent;
      margin-bottom: 17px;
    }

    @keyframes circ-anim {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
