@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  
.spinner {
  display: inline-block;
  border: 3px solid white;
  border-left-color:#B250FF;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: donut-spin 1.2s linear infinite;
}