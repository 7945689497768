@import 'styles/colors';

$main_color_light: $dark_color;
$divider_color_light: #d0cfd0;

$main_color_dark: $white_color;
$divider_color_dark: rgba(#d0cfd0, 0.2);

$main_color: var(--main-color);
$background_color: var(--background-color);
$divider_color: var(--divider-color);
$placeholder_color: var(--placeholder-color);
$selector_arrow: var(--selector-arrow);
$disabled_button_background: var(--disabled-button-background);

.container {
  display: flex;
  flex-direction: column;

  --main-color: #{$main_color_light};
  --background-color: #{rgba($main_color_light, 0.07)};
  --divider-color: #{$divider_color_light};
  --placeholder-color: #{rgba($main_color_light, 0.5)};
  --selector-arrow: url(../../../../../assets/ic_dropdown_arrow.svg);
  --disabled-button-background: linear-gradient(
    270deg,
    0%,
    $main_accent_color 100%
  );

  &.dark {
    --main-color: #{$main_color_dark};
    --background-color: #{rgba($main_color_dark, 0.07)};
    --divider-color: #{$divider_color_dark};
    --placeholder-color: #{rgba($main_color_dark, 0.5)};
    --selector-arrow: url(../../../../../assets/ic_dropdown_arrow_light.svg);
    --disabled-button-background: $gradient;
  }

  .countrySelector {
    height: 52px;
    border-radius: 50px;
    padding: 0 20px;
    font-family: 'Euclid Circular A';
    font-size: 16px;
    border-color: transparent;
    background: var(--background-color) var(--selector-arrow) no-repeat right
      15px top 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--main-color);
    font-weight: 500;
    margin-top: 5px;
    outline: none;
    width: 100%;
  }

  .fieldsContainer {
    display: flex;
    justify-content: center;

    .dateDivider {
      margin: 20px 8px 0;
      color: var(--placeholder-color);
    }
  }

  .cvvWrapper {
    display: flex;
    align-items: center;
    width: auto;
    height: 52px;
    margin: 5px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    background-color: var(--background-color);
    border: none;
    border-radius: 40px;
    position: relative;

    .cvvElement {
      width: 100%;
    }

    :global {
      .recurly-element-cvv {
        background: transparent;
        border: none;
        padding-left: 20px;
        height: 52px;
        width: 100%;
        margin: 0;

        &.recurly-element-valid {
          background: url(../../../../../assets/ic_check.svg) no-repeat scroll
            right 18px top 18px;
        }
      }
    }

    .questionMark {
      height: 17px;
      width: 17px;
      padding: 0 17px 0 0;
      display: none;

      &.visible {
        display: block;
      }
    }
  }

  :global {
    .recurly-element-number,
    .recurly-element-month,
    .recurly-element-year {
      width: 100%;
      height: 52px;
      margin: 5px 0;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      background-color: var(--background-color);
      border: none;
      border-radius: 40px;
      padding: 0 20px;
      caret-color: $secondary_accent_color;

      &.recurly-element-number {
        background: var(--background-color)
          url(../../../../../assets/ic_lock.svg) no-repeat scroll right 18px top
          18px;
      }

      &.recurly-element-valid {
        background: var(--background-color)
          url(../../../../../assets/ic_check.svg) no-repeat scroll right 18px
          top 18px;
      }
    }
  }

  .invalid {
    :global {
      .recurly-element {
        color: #e34646;
        background-color: rgba(227, 70, 70, 0.1);
      }
    }
  }

  .formError {
    font-size: 12px;
    line-height: 22px;
    color: #e34646;
    margin-left: 15px;
  }

  .input {
    margin: 5px 0;
    font-size: 16px;
    color: var(--main-color);
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.2px;
    height: 52px;
    background-color: var(--background-color);
    border: none;
    border-radius: 40px;
    padding: 0 20px;
    caret-color: $secondary_accent_color;

    &::placeholder {
      color: var(--placeholder-color);
      font-weight: normal;
    }

    &:focus {
      outline: none;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    opacity: 1;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    &::after {
      content: '';
      height: 18px;
      width: 20px;
      margin-left: 5px;
      background: url(../../../../../assets/ic_lock_white.svg) no-repeat;
    }
  }
}
