@import 'styles/colors';
@import 'styles/variables';

.paymentContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 20px 40px;
  text-align: left;

  &.bottom90 {
    margin-bottom: 90px;
  }

  .hidden {
    display: none;
  }
}

.payment {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  max-width: $max-content-width;
  margin: 0 auto;
  width: 100%;
}

.footer {
  margin-top: 30px;
}
