@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 20px;
  box-sizing: border-box;
  flex: 1 1;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: $max-content-width;
    align-self: center;
    flex: 1 1 auto;

    .label {
      font-size: 16px;
      line-height: 24px;
      color: rgba($dark_color, 0.55);
      font-weight: 600;
    }

    .title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      color: $dark_color;
      margin-top: 4px;
    }

    .cardContainer {
      width: 100%;
      max-width: $max-content-width;
      background-size: auto 100%;
      height: 102px;
      background-repeat: no-repeat;
      background-position: top right;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .label {
        font-size: 16px;
        line-height: 22px;
        color: $white_color;
        margin-left: 20px;
        margin-bottom: 8px;
      }

      .time {
        font-size: 28px;
        line-height: 22px;
        font-weight: 700;
        color: $white_color;
        margin-left: 20px;
        margin-bottom: 8px;
      }

      &.bedTimeCard {
        background-image: url(../../../assets/onboarding/moon.svg);
        background-color: #2b0050;
        margin-bottom: 10px;
      }

      &.wakeUpCard {
        background-image: url(../../../assets/onboarding/sun.svg);
        background-color: #ff9100;
      }
    }

    .description {
      max-width: 450px;
      font-size: 16px;
      line-height: 22px;
      color: #6b6770;
      text-align: center;
      margin: 10px 25px 10px;
    }

    .figcaption {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      text-align: center;
      margin: 15px 30px 10px;
    }

    .note {
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: rgba($dark_color, 0.6);
      margin: 10px 40px 0;
      white-space: pre-wrap;
    }

    .button {
      display: block;
    }
  }
}
