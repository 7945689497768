@import 'styles/colors';

.planContent {
  display: flex;
  flex-direction: column;
  width: 100%;

  .priceOld {
    text-decoration: line-through;
    /* Your styles for the old (strikethrough) price */
  }

  .priceNew {
    font-weight: bold;
    /* Your styles for the new price */
  }

  &.st_v3 {
    .field {

      &.totalSave {
        .label {
          color: $active_green_color;
        }

        span {
          color: $active_green_color;
        }
      }

      &.discount {
        .purpleBadge {
          background: $active_green_color;
        }
      }

      &.skipTrial {
        margin-bottom: 14px;

        &.accent {
          .label {
            color: $active_green_color;
          }
        }
      }

      .prices {
        display: flex;
        gap: 10px;
  
        .price {
          margin-top: 2px;
          
          &.green {
            color: $active_green_color;
          }
        }
  
        .priceLabel {
          color: $white_color;
          padding: 2px 8px;
          background-color: $active_green_color;
          border-radius: 8px;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    color: $dark_color;
    margin-bottom: 10px;
    margin-top: 32px;
    font-size: 22px;
    line-height: 18px;
    font-weight: 600;

    span {
      color: rgba($dark_color, 0.64);
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  .field {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    color: $dark_color;

    .label {
      font-size: 16px;
      line-height: 24px;

      &.normal {
        font-weight: 400;
      }
    }

    &.total {
      margin: 16px 0 0;
      line-height: 26px;
      font-weight: 700;
      color: $dark_color;

      .label {
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
      }
    }

    &.skipTrial {
      margin: 8px 0;
      color: $dark_color;

      .label {
        font-weight: 600;
      }

      span {
        font-weight: 400;
      }

      &.accent {
        color: $main_accent_color;

        span {
          font-weight: 600;
        }
      }
    }

    &.discount {
      margin: 4px 0;

      .purpleBadge {
        background: $gradient;
        border-radius: 7px;
        color: $white_color;
        font-weight: 600;
        font-size: 14.1818px;
        line-height: 26px;
        margin-left: 0;
        letter-spacing: 0.236364px;
        padding: 0 8px;
        text-align: left;
      }
    }

    &.totalSave {
      margin: 8px 0;
      color: $main_accent_color;
      line-height: 24px;

      .label {
        font-weight: 600;
      }

      span {
        font-weight: 600;
      }
    }
  }
}
