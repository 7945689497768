.pricesContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 10px;

  > div {
    max-height: inherit;
  }
}
