.container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-left: 12px;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;

  .boldDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .addInfo {
    font-size: 12px;
    line-height: 16px;
  }
}
