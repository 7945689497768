@import 'styles/colors';
@import 'styles/variables';

.logo {
  align-self: center;
  position: absolute;
  top: 20px;

  path {
    width: 58px;
    height: 20px;
    fill: #000000;
  }
}

.socialLogin {
  margin-top: 15px;
  height: auto;
  opacity: 1;
  transition: all 0.5s;

  &.hidden {
    overflow: hidden;
    height: 0;
    opacity: 0;
  }

  .socialLoginButton {
    border: 1px solid rgba($dark_color, 0.15);
    color: $dark_color;
    background-color: transparent;
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;

    img,
    svg {
      padding-right: 10px;
    }
  }
}

.topMargin {
  margin-top: 18px;
}

.signIn {
  justify-content: flex-start;
}

.template {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  padding: 0 20px;

  .templateContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    flex-grow: 1;
    max-width: $max-content-width;

    .newStepper {
      padding: 0;
    }

    .question {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.4px;
      margin: 0;

      &.topMargin {
        margin: 23px 0 0;
      }
    }
    .description {
      margin-top: 8px;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
}
