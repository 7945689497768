@import 'styles/colors';

.modal {
  max-width: 335px;
  box-sizing: border-box;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -5px 20px 22px;
    overflow: hidden;

    .discountBackground {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 180px;
      background: url('../../../../../../assets/discount_bg.png') no-repeat
        center;

      svg {
        margin-top: 20px;
      }
    }

    .offer {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      font-weight: 600;
      white-space: pre-wrap;
      color: $dark_color;
      margin-top: -10px;
      margin-bottom: 4px;

      p {
        margin: 10px;
      }

      .accent {
        color: rgb(128, 45, 246);
      }
    }
  }
}
