@import 'styles/colors';

.button {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  :global {
    .abcRioButtonLightBlue {
      color: $white_color;
      opacity: 1;
    }
  }

  :first-child {
    font-family: 'Euclid Circular A', sans-serif;
    align-items: center;
    background-color: transparent;
    background-image: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-left: -3px;
    box-shadow: unset;
    transition: unset;

    &:hover {
      box-shadow: unset;
      transition: unset;
    }
  }

  span {
    margin-left: 0;
  }
}