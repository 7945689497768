@import 'styles/colors';

.loadingButton {
  span::first-letter {
    text-transform: capitalize;
  }
}

button {
  &.secondary {
    background: white;
    color: $dark_color;
    margin-top: 10px;

    &.st_v3 {
      background-color: #F4F1E8;
      color: $main_accent_color;
      margin-top: 2px;
    }
  }
}
