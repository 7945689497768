@import 'styles/colors';

.plus {
  padding: 2px 6px;
  margin-left: 6px;
  position: relative;
  bottom: 2px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  background: $main_accent_color;
  border-radius: 6.00289px;
  color: $white_color;
}
