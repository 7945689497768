@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 20px;
  flex: 1 0 auto;
  max-width: $max-content-width;
  align-self: center;
  width: calc(100% - 40px);

  .logo {
    margin-top: 20px;
    margin-bottom: 30px;
    align-self: center;
  }

  .socialLogin {
    margin-top: 15px;
    height: auto;
    opacity: 1;
    transition: all 0.5s;

    &.hidden {
      overflow: hidden;
      height: 0;
      opacity: 0;
    }

    :global {
      .social-login-button {
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: white;
        background-color: transparent;
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        cursor: pointer;

        img,
        svg {
          padding-right: 10px;
        }
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $white_color;
  }

  .description {
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: $white_color;
    margin-top: 9px;
  }

  .form {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 52px;
    }

    .forgotPassword {
      color: rgba($white_color, 0.5);
      margin-bottom: 20px;
      margin-top: 5px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      opacity: 0.5;
      background: none;
      height: 24px;
      width: initial;
      align-self: center;
    }

    .error {
      font-size: 12px;
      line-height: 22px;
      color: #e34646;
      margin-left: 15px;
      margin-right: 15px;
    }

    .inputContainer {
      position: relative;
      display: flex;

      input[type='email'],
      input[type='password'],
      input[type='text'] {
        flex: 1;
        padding: 0 60px 0 20px;
        background-color: rgba(255, 255, 255, 0.07);
        color: rgba(255, 255, 255, 1);

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .delete {
        position: absolute;
        top: 0;
        right: 22px;
        bottom: 0;
        margin: auto 0;
        height: 24px;
      }
    }

    .password {
      input[type='password'],
      input[type='text'] {
        background-color: rgba(255, 255, 255, 0.07);
        color: rgba(255, 255, 255, 1);

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
        &.invalid {
          color: #e34646;
          background-color: rgba(227, 70, 70, 0.1);
        }
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 52px;

      &:disabled {
        background: $gradient;
        opacity: 0.3;
      }
    }
  }

  &.whiteTheme {
    .logo {
      path {
        fill: $dark_color;
      }
    }

    .title,
    .description {
      color: $dark_color;
    }

    :global {
      #g-signin2 {
        border: 1px solid rgba($dark_color, 0.15);

        .abcRioButton {
          color: $dark_color;
        }
      }
      
      .social-login-button {
        border: 1px solid rgba($dark_color, 0.15);
        color: $dark_color;
      }
    }

    .form {
      button {
        &:disabled {
          background: rgba(9, 1, 16, 0.5);
        }
      }

      .forgotPassword {
        color: $dark_color;
        background: none;
      }
    }

    .inputContainer {
      position: relative;
      display: flex;

      input[type='email'],
      input[type='password'],
      input[type='text'] {
        flex: 1;
        padding: 0 60px 0 20px;
        background-color: rgba($dark_color, 0.07);
        color: rgba($dark_color, 1);

        &::placeholder {
          color: rgba($dark_color, 0.5);
        }
      }

      .delete {
        position: absolute;
        top: 0;
        right: 22px;
        bottom: 0;
        margin: auto 0;
        height: 24px;
      }
    }

    .password {
      input[type='password'],
      input[type='text'] {
        background-color: rgba($dark_color, 0.07);
        color: rgba($dark_color, 1);

        &::placeholder {
          color: rgba($dark_color, 0.5);
        }
        &.invalid {
          color: #e34646;
          background-color: rgba(227, 70, 70, 0.1);
        }
      }
    }
  }
}
