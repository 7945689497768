@import 'styles/colors';

.facebook-review {
  border: 1px solid #d0cfd0;
  border-radius: 4px;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 14px;
  }

  .review-text {
    margin: 15px 0 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: $dark_color;
  }

  .review-header {
    display: flex;
    margin-bottom: 15px;

    .review-author {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 12px;
      height: 40px;

      .review-title {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        line-height: 20px;
        color: #44588f;
        margin-bottom: 0;
      }

      .review-subtitle {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        color: rgba($dark_color, 0.4);
        margin-top: 0;
      }
    }

    .review-avatar {
      width: 44px;
      height: 44px;
      border-radius: 44px;
    }
  }
}
