@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;

  .particles {
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('../../../assets/bg_particles_big_purple.png') center
      no-repeat;
    background-size: cover;
  }

  .content {
    z-index: 2;
  }

  .overlay {
    background: url('../../../assets/bg_fog.png') center no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
  }

  .gradient {
    mix-blend-mode: darken;
    position: absolute;
    z-index: 1;

    &.start {
      background: linear-gradient(
        90deg,
        $dark_purple_color 0%,
        rgba(11, 2, 22, 0) 100%
      );
      width: 20%;
      height: 100%;
      left: 0;
    }

    &.end {
      background: linear-gradient(
        270deg,
        $dark_purple_color 0%,
        rgba(11, 2, 22, 0) 100%
      );
      width: 20%;
      height: 100%;
      right: 0;
    }
  }
}
