@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: -20px;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 20px 40px;
    max-width: 480px;
    z-index: 2;

    .title {
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      color: $dark_color;
      margin-bottom: 12px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      font-weight: 400;
      color: $dark_color;
    }
  }

  .image {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: bottom;
  }
}