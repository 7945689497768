@import 'styles/colors';

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  opacity: 1;
  transition: opacity 0.5s;
  background-color: $white_color;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2px;
  color: rgba($dark_color, 0.5);
  align-items: center;

  &.dark {
    background-color: $dark_purple_color;
    color: white;

    .spinner {
      border: 3px solid $light_violet_color;
      border-bottom-color: $main_accent_color;
    }

    h3 {
      color: $white_color;
    }
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.6px;
    color: $dark_color;
  }

  .spinner {
    $size: 36px;
    $speed: 0.7s;
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: 3px solid $light_violet_color;
    animation: circ-anim $speed linear infinite;
    border-bottom-color: $main_accent_color;
    margin: 90px 1em 1em;
  }

  @keyframes circ-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
