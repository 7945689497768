@import 'styles/colors';

.container {
  height: 155px;
  max-height: 155px;
  border-radius: 16px;
  padding: 16px 16px 18px;
  color: $white_color;

  &:nth-child(1), &:nth-child(2) {
    opacity: 0;
    top: 300px;
    animation: smooth-appear 0.5s ease-in forwards;
  }

  &:nth-child(1) {
    animation-delay: 0ms;
  }

  &:nth-child(2) {
    animation-delay: 200ms;
  }

  &:nth-child(3), &:nth-child(4) {
    opacity: 0;
    top: 300px;
    animation: smooth-appear 0.5s ease-in forwards;
  }

  &:nth-child(3) {
    animation-delay: 400ms;
  }

  &:nth-child(4) {
    animation-delay: 500ms;
  }

  h3 {
    margin: 6px 0;
  }

  &.person {
    background-color: #D78E5F;
  }

  &.activities {
    background-color: #CB61B3;
  }

  &.narrative_preferences {
    background-color: #93912E;
  }

  &.color_preferences {
    background-color: #806CD4;
  }
}

@keyframes smooth-appear {
  to {
    top: 200px;
    opacity: 1;
  }
}