@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 26px 0 0;
  transition: opacity 250ms ease-in;
  opacity: 0.01;
  padding: 0 20px;
  box-sizing: border-box;

  &.speed {
    transition: opacity 150ms ease-in;
  }

  &.enter {
    opacity: 1;
  }

  &.leave {
    opacity: 0;
  }

  .title {
    text-align: center;
    margin-top: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.4px;
  }

  .imageContainer {
    position: relative;

    .image {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 16px;
      opacity: 0;

      &.maxHeight {
        max-height: 60vh;
      }

      &.transition {
        @include animation('fade-in .3s ease');
      }

      &.visible {
        opacity: 1;
      }

      @include keyframes(fade-in) {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba($dark_color, 0.7);
    letter-spacing: 0.2px;
    margin: 12px 10px;
    max-width: 430px;
    align-self: center;
  }

  .button {
    height: 52px;
    min-height: 52px;
    margin-bottom: 20px;
  }
}
