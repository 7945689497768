@import 'styles/colors';
@import 'styles/mixins';

.container {
  background-color: rgba(#c4c4c4, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 20px 20px;
  transform: translateX(-110%);
  opacity: 0;
  flex-shrink: 0;
  -webkit-transform: translateX(-110%);

  &.transitionIn {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  &.transitionOut {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
  @keyframes slide-in {
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%);
      opacity: 1;
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      transform: translateX(110%);
      opacity: 0;
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
      opacity: 0;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: $dark_color;
    margin: 10px 0;
  }

  .signature {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: rgba($dark_color, 0.6);
  }
}
