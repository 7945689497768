@import 'styles/variables';

.title {
  color: white;
  font-weight: bold;
  font-size: 34px;
  line-height: 43px;
  text-align: center;
  letter-spacing: -0.2px;
  margin-bottom: 18px;

  .accent {
    color: #d295ff;
  }
}

.disclaimer {
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.6);
}

@media (min-width: $breakpoint-tablet) {
  .title {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 42px;
  }

  .button {
    width: 250px;
  }
}
