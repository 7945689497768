@import 'styles/variables';

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1 0;
  padding: 20px 0;
  position: relative;

  .logo {
    position: absolute;
    top: 40px;
    width: 90px;
    height: 30px;
  }

  .cardContainer {
    max-width: 120%;
    object-fit: contain;
  }

  .textWrapper {
    margin: 0 20px;
    max-width: 335px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .textWrapper {
    max-width: 335px;
    align-items: center;
    margin: 20px;
  }
}
