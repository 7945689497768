.wrapper {
  margin-top: 40px;

  .title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .logosWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    > div {
      display: flex;
      gap: 30px;
      margin: 20px;
    }
  }
}
