.advantagesWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 11px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 190px;
  margin-bottom: 20px;
  max-width: 360px;
}

.colorsWrapper {
  display: flex;
  position: relative;
  margin-top: 20px;
}
