.container {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;

  .content {
    z-index: 2;
  }

  .overlay {
    background: url('../../../assets/bg_fog_mobile.png') center no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .particles {
      width: 100%;
      height: 100%;
      z-index: 2;
      background: url('../../../assets/bg_particles_big_purple.png') center
        no-repeat;
      background-size: cover;
      position: absolute;
    }

    .logo {
      width: 56px;
      height: 18px;
      position: absolute;
      top: 20px;
    }

    .cardContainer {
      max-width: 90%;
      margin-top: 45px;
    }
  }
}
