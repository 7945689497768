@import 'styles/colors';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: $dark_color;
    text-align: left;
    margin: 24px 20px 12px;

    .accent {
      color: $main-accent_color;
    }
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba($dark_color, 0.64);
    margin: 0 20px;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #F4F1E8;
    padding: 32px 20px 10px;
    margin-top: 32px;

    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: $dark_color;
      margin: 0;
    }

    .description {
      margin: 4px 0 32px;
    }

    ul, li {
      margin: 0;
      padding: 0;
    }

    .buttonContainer {
      background: linear-gradient(
        to top, 
        #F4F1E8,
        #F4F1E8 50%, 
        #F4F1E8 57%, 
        rgba(#F4F1E8, 0) 100%
      );
    }

    .benefitsList {
      margin-top: 16px;

      li {
        list-style: none;
        margin-top: 12px;

        .check {
          margin-right: 5px;
        }
      }
    }
  }
}