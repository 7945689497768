@import 'styles/mixins';
@import 'styles/colors';

.footer {
  display: flex;
  flex-direction: column;
  text-align: center;

  .links {
    display: flex;
    justify-content: center;

    a {
      @include noSelect;
      color: $dark_color;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.4px;
      padding: 5px 10px;
      text-decoration: none;
    }
  }

  .copyright {
    margin-top: 0;
    margin-bottom: 20px;
    color: rgba($dark_color, 0.4);
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
}
