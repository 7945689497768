@import 'styles/colors';

.darkBodyBackground {
  background-color: $dark_purple_color;
}

.whiteBodyBackground {
  background-color: $white_color;
}

.dark {
  color: $white_color;
}

.white {
  color: $dark_color;
}
