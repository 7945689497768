@import 'styles/colors';

.totalHeader {
  display: flex;
  margin-top: 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

.total {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  .prices {
    display: flex;
    gap: 7px;

    .crossed {
      color: rgba(#090110, 0.4);
      text-decoration: line-through;
    }
  }

  span {
    margin-left: auto;
    text-align: right;
    color: $dark_color;
    line-height: 22px;
    letter-spacing: 0.2px;

    &.accent {
      color: #c68bfd;
    }

    &.bold {
      font-weight: 600;
    }
  }

  .trialExplanation {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba($dark_color, 0.64);
  }
}
