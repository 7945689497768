@import 'styles/mixins';
@import 'styles/variables';

.logo {
  align-self: center;
  position: absolute;
  top: 20px;

  path {
    width: 58px;
    height: 20px;
    fill: #000000;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  &.greenColor {
    background-color: #67c3ad;
  }

  &.blueColor {
    background-color: #77a1cd;
  }

  &.purpleColor {
    background-color: #9277cd;
  }

  :global {
    .maxWidth {
      max-width: $max-content-width;
      width: 100%;
      align-self: center;
    }
  }

  button {
    @include noSelect;

    &:active {
      transform: scale(0.98);
      box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
