.divider {
  height: 1px;
  margin: 20px 0;
  background-color: #d0cfd0;

  &.darkTheme {
    background-color: hsla(300, 1%, 81%, 0.2);
  }

  &.dividerWithNoMargin {
    margin: 0;
  }

  &.dividerWithMargin {
    margin: 35px 0 0;
  }
}
