@import 'styles/colors';
@import 'styles/variables';

.quick-purchase-sticky-container {
  padding: 0 20px;
  transition: all 0.2s, padding 0s;
  position: relative;

  svg {
    position: absolute;
  }

  .left {
    top: 30px;
    left: 30px;
  }

  .right {
    bottom: 10px;
    right: 30px;
    transform: rotate(150deg);
  }

  &.sticky {
    position: sticky;
    top: 0;
    background-color: $light_cream_color;
    padding: 0 20px 14px;
    z-index: 1;

    .hidden {
      display: none;
    }

    .active-timer {
      display: block;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $secondary_accent_color;
      margin-top: 20px;
    }
  }

  .active-timer {
    display: none;
  }
}

.quick-purchase-timer-box {
  background-color: $light_cream_color;
  border-radius: 16px;
  padding: 24px 16px;
  margin-top: 10px;
  text-align: left;
  font-weight: 500;

  &.top-margin-17 {
    text-align: center;
    margin-top: 17px;
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    white-space: pre-line;

    .accentTitle {
      font-weight: bold;
      color: $secondary_accent_color;
    }
  }

  .accent {
    font-size: 16px;
    font-weight: bold;
    color: $secondary_accent_color;
    margin: 16px 0 0;
  }

  .light {
    color: rgba($dark_color, 0.5);
    font-weight: 400;
  }
}
