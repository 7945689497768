@import 'styles/colors';

.loadingButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  &:disabled {
    background: rgba($dark_color, 0.12) none;
    opacity: 1;
  }

  &.dark:disabled {
    background: $gradient;
    opacity: 0.3;
  }

  img {
    margin-right: 7px;
  }
}
