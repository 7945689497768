.signInForm {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;

  .signInError {
    font-size: 12px;
    line-height: 22px;
    color: #e34646;
    margin-left: 15px;
    margin-right: 15px;
  }

  .inputContainer {
    position: relative;
    display: flex;

    input {
      flex: 1;
      padding: 0 60px 0 20px;

      &.new {
        border: 1px solid #848087;
        border-radius: 30px;
        background: none;

        &.invalid {
          border: 1px solid #e34646;
        }
      }
    }

    .delete {
      position: absolute;
      top: 0;
      right: 22px;
      bottom: 0;
      margin: auto 0;
      height: 24px;
    }
  }
}
