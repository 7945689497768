@import 'styles/variables';
@import 'styles/colors';

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  flex: 1;
  position: relative;

  .logo {
    width: 56px;
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    left: 0;
    right: 0;
  }

  .background {
    position: relative;

    .backgroundImage {
      width: 100%;
    }

    .gradient {
      position: absolute;
      height: 100px;
      width: 100%;
      bottom: 0;
      background: linear-gradient(180deg, #0a0111 0%, rgba(10, 1, 17, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  .textWrapper {
    margin: 0 auto 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;

    .title {
      color: $white_color;
      font-weight: bold;
      font-size: 34px;
      line-height: 43px;
      text-align: center;
      letter-spacing: -0.2px;
      margin-bottom: 18px;

      .accent {
        color: #d295ff;
      }
    }

    .disclaimer {
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.2px;
      color: rgba($white_color, 0.6);
    }
  }
}

.bigBackgroundContainer {
  background: url('../../../../../assets/largeRedeemBackground/bg_cards.png')
    center no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;

  .topGradient {
    background: linear-gradient(180deg, #110321 0%, rgba(17, 3, 33, 0) 100%);
    mix-blend-mode: darken;
    height: 23%;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .bottomGradient {
    background: linear-gradient(180deg, #110321 0%, rgba(17, 3, 33, 0) 100%);
    mix-blend-mode: darken;
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 23%;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .overlayContainer {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #190531 32%,
      transparent 100%
    );
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textWrapper {
    margin: 0;
    max-width: 335px;
    width: 100%;
  }
}

@media (min-width: $breakpoint-tablet) {
  .contentContainer {
    .logo {
      height: 30px;
      width: 90px;
      margin-top: 50px;
    }

    .textWrapper {
      position: relative;
      background: none;
    }
  }
}
