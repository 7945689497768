@import 'styles/colors';

.checkbox {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .radioBtn {
    display: flex;
    align-items: center;
  }

  .price {
    margin-left: auto;
    font-weight: 500;
    color: rgba($dark_color, 0.3);

    &.accent {
      color: #c68bfd;
    }
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 2px;
    background-clip: content-box;
    border: 2px solid rgba(128, 45, 246, 0.5);
    background-color: $white_color;
    border-radius: 50%;
    margin-right: 10px;
  }

  input[type='radio']:checked {
    background-color: $main_accent_color;
    border: 2px solid $main_accent_color;
  }
}
