@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  .card {
    max-width: 350px;
    max-height: 180px;
    display: flex;
    gap: 16px;
    justify-content: center;
    flex-direction: column;
    margin: 32px auto;
    background: $light_cream_color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    position: relative;
    padding: 32px 20px;
    box-sizing: border-box;

    .input {
      flex-grow: 1;
    }

    .inputsWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 9px;

      &.cardElement {
        flex-direction: column;
        gap: 2px;
      }
    }
  }

  .hidden {
    display: none;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    opacity: 1;

    &.hidden {
      display: none;
    }

    &:disabled {
      &::before {
        content: '';
        height: 18px;
        width: 20px;
        background: url(../../../../../assets/ic_lock_white.svg) no-repeat;
      }
    }
  }
}
