@import 'styles/colors';

.total {
  display: flex;
  margin: 13px 0;

  &.bottom9 {
    margin: 13px 0 9px;
  }

  &.top0 {
    margin: 0 0 13px;
  }

  span {
    margin-left: auto;
    text-align: right;
    color: $dark_color;
    line-height: 22px;
    letter-spacing: 0.2px;

    &.purpleBadge {
      background: $gradient;
      border-radius: 7px;
      color: $white_color;
      font-weight: 600;
      font-size: 14.1818px;
      line-height: 26px;
      margin-left: 0;
      letter-spacing: 0.236364px;
      padding: 0 8px;
      text-align: left;
    }

    &.accent {
      color: #c68bfd;
    }

    &.light {
      color: rgba($dark_color, 0.3);
    }

    &.grey {
      color: rgba($dark_color, 0.6);
    }

    &.bold {
      font-weight: 500;
    }
  }
}
