@import 'styles/colors';

.tooltip {
  border-radius: 16px;
  position: absolute;
  bottom: 100%;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  opacity: 0;

  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $dark_color;

  &-img {
    height: 92px;
    margin-bottom: 10px;
  }

  &-arrow {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateX(-50%);
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      width: 22px;
      height: 22px;
      background: white;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: -5px;
      left: 50%;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
