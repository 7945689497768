@import 'styles/colors';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
  padding: 0 20px;
  box-sizing: border-box;

  .content {
    flex: 1 1 auto;
    display: flex;
    position: relative;

    .innerContent {
      overflow-y: auto;
      flex: 1 1 auto;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .bottomGradient {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      background: linear-gradient(
        180deg,
        rgba($white_color, 0) 0%,
        $white_color 100%
      );
    }
  }

  .title {
    text-align: center;
    margin-top: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    color: $dark_color;
    margin-bottom: 14px;
  }

  .graphDescription {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    color: $dark_color;
    margin-top: 56px;
    margin-bottom: 25px;
  }

  .progressText {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #352e2e;
    white-space: pre;
    margin-bottom: 10px;
  }

  .button {
    flex-shrink: 0;
  }
}
