.info {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;

  a {
    color: rgba(155, 113, 239, 1);
    text-decoration: none;
  }
}
