@import 'styles/colors';
@import 'styles/variables';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0;
  max-width: $max-content-width;
  align-self: center;

  @media (max-height: 600px) {
    flex: 1 0 auto;
  }
}

.button {
  justify-self: flex-end;
  max-width: initial;
  min-height: 52px;
  margin-bottom: 20px;

  &.pulseEffect {
    box-shadow: 0 0 0 0 #d0a4ff;
    animation: pulse 4s infinite;

    &:hover {
      animation: none;
    }
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 50px rgba($light_violet_color, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($light_violet_color, 0);
  }
}
