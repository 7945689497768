@import 'styles/colors';

.modal {
  max-width: 335px;
  // box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image {
    max-width: 190px;
    max-height: 174px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px;
    overflow: hidden;

    .discountBackground {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 180px;
      background: url('../../../../assets/discount_bg.png') no-repeat center;

      svg {
        margin-top: 20px;
      }
    }

    .title {
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      font-weight: 700;
      white-space: pre-wrap;
      color: $dark_color;
      margin-top: -10px;
      margin-bottom: 10px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: rgba($color: #090110, $alpha: 0.64);
    }
  }
}
