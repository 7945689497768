.title {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 4px;

  .accent {
    color: #d295ff;
  }
}

.advantages {
  margin: 0;
  padding: 0;

  li {
    font-size: 14px;
    line-height: 20px;
    margin: 20px 16px;
    list-style: none;

    .accent {
      color: #d295ff;
    }

    &::before {
      content: '\2022';
      font-weight: bold;
      display: inline-block;
      font-size: 16px;
      width: 10px;
      margin-left: -10px;
      color: #d295ff;
    }
  }
}
