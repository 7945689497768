@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: 24px 20px 0;
  flex: 1;

  .title {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
    color: $dark_color;
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 32px;

    .accent {
      color: $main_accent_color;
    }
  }

  .note {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 40px;
    text-align: center;

    .accent {
      color: $main_accent_color;
    }
  }

  .content {
    display: flex;
    flex: 1 1 auto;

    .stickyContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .button {
        &.secondary {
          margin: 0;
          background: $white_color;
          color: $dark_color;
        }
      }
    }
  }
  
  &.maxWidth {
    max-width: 480px;
  }
}