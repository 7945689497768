@import 'styles/variables';

.contentContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 0;

  .textWrapper {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .cardContainer {
      max-width: 130%;
      object-fit: contain;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .contentContainer {
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;

    .logo {
      height: 30px;
      width: 90px;
    }

    .textWrapper {
      align-items: center;
      max-width: 335px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
