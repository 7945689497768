@import 'styles/colors';
@import 'styles/variables';

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($dark_color, 0.4);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: unset;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .container {
    background: $white_color;
    box-shadow: 0 4px 40px rgba($dark_color, 0.25);
    border-radius: 16px;
    margin: 20px;
    padding: 20px;
    width: 100%;
    max-width: $max-content-width;

    .title {
      text-align: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 29px;
      letter-spacing: 0.2px;
    }

    .buttons {
      display: flex;

      .button {
        background: $light_cream_color;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: $dark_color;
        cursor: pointer;
        transition: 150ms all;
        height: 52px;

        &.active {
          color: $white_color;
          background: $active_green_color;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
