@import 'styles/colors';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  flex-direction: column;
  margin: 0 20px;

  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    color: $dark_color;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    color: $dark_color;
    margin-top: 12px;
  }
}