@import 'styles/colors';

.container {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: rgba($dark_color, 0.6);
  display: flex;
  flex-direction: column;
}
