@import '../../../../styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-bottom: 24px;
    color: $dark_color;
  }
}
