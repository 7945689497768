@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0 40px;
  box-sizing: border-box;
  flex: 1 1;

  &.animal {
    .contentWrapper {
      max-width: 335px;
      
      .title {
        color: $dark_color;
      }

      .subtitle, 
      .description,
      .figcaption {
        color: rgba($dark_color, 0.5);
      }

      .sticker {
        margin-top: 26px;
        margin-bottom: 16px
      }

      .cardContainer {
        background-color: $white_color;

        &.bedTimeCard {
          background-image: url(../../../../assets/meqresults/moon_v2.svg);

          .label, .time  {
            color: #91C1F2;
          }
        }

        &.wakeUpCard {
          .label, .time {
            color: #F1B400;
          }
        }
      }

      .stickyContainer {
        width: 100%;
        z-index: 3;
      }
    }

    &.morning {
      .contentWrapper {
        background-color: #F4F1E8;

        .stickyContainer {
          background: linear-gradient(
            to top, 
            #F4F1E8 60%, 
            rgba(#F4F1E8, 0.2)
          );
        }
      }
    }

    &.neutral {
      .contentWrapper {
        background-color: #E9F3FC;

        .stickyContainer {
          background: linear-gradient(
            to top, 
            #E9F3FC 60%, 
            rgba(#E9F3FC, 0.2)
          );
        }
      }
    }

    &.evening {
      .contentWrapper {
        background-color: #F4EFF9;

        .stickyContainer {
          background: linear-gradient(
            to top, 
            #F4EFF9 60%, 
            rgba(#F4EFF9, 0.2)
          );
        }
      }
    }
  }

  &.evening {
    .contentWrapper {
      background-color: #01101D;
    }

    .stickyContainer {
      background: linear-gradient(
        to top, 
        #01101D 70%, 
        rgba(#01101D, 0.2)
      );
    }
  }

  .logo {
    width: 72px;
    height: 24px;

    path {
      fill: #1b1b1b;
    }
  }

  .contentWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    align-self: center;
    background-color: #0E1B23;
    border-radius: 32px;
    margin: 24px 20px 10px;
    max-width: 375px;
    box-sizing: border-box;
    padding: 0 20px 10px;

    .background {
      z-index: 1;
      position: absolute;
      object-fit: contain;
      max-width: 100%;
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
      color: rgba($white_color, 0.5);
      font-weight: 600;
      z-index: 2;
      margin-top: 32px;
    }

    .title {
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      color: $white_color;
      margin-top: 4px;
      z-index: 2;
    }

    .sticker {
      margin-top: 20px;
      z-index: 2;
      object-fit: contain;
      max-width: 100%;
    }

    .description {
      font-size: 16px;
      line-height: 22px;
      color: rgba($white_color, 0.5);
      text-align: center;
      margin: 10px 5px 32px;
      z-index: 2;
      font-weight: 500;
    }

    .cardContainer {
      width: 100%;
      background-size: auto 100%;
      min-height: 90px;
      background-repeat: no-repeat;
      background-position: top right;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 2;
      background-color: rgba(#FFFFFF, 0.08);

      .label {
        font-size: 16px;
        line-height: 22px;
        color: rgba($white_color, 0.5);
        margin-left: 20px;
        margin-bottom: 8px;
        font-weight: 500;
      }

      .time {
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        color: $white_color;
        margin-left: 20px;
        margin-bottom: 8px;
      }

      &.bedTimeCard {
        background-image: url(../../../../assets/meqresults/moon.svg);
        margin-bottom: 12px;
      }

      &.wakeUpCard {
        background-image: url(../../../../assets/meqresults/sun.svg);
      }
    }

    .figcaption {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      margin: 16px 0 12px;
      z-index: 2;
      color: rgba($white_color, 0.5);
    }
  }

  .note {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
    color: rgba($dark_color, 0.5);
    margin: 22px 40px 0;
    white-space: pre-wrap;
    z-index: 2;
  }

  .stickyContainer {
    width: 100%;
    z-index: 3;
    background: linear-gradient(
      to top, 
      #0E1B23 60%, 
      rgba(#0E1B23, 0.2)
    );
  }
}
