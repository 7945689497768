@import 'styles/colors';

.modal {
  max-width: 335px;
  box-sizing: border-box;
}

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    
  .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
    margin-top: -10px;
    z-index: 2;
  }
  
  img {
    max-width: 100%;
    margin-top: -20px;
    min-height: 200px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;

    .accent {
      color: $secondary_accent_color;
    }
  }
  
  button {
    margin-bottom: 27px;
  }
}