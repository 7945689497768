@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  color: $white_color;
  background-image: url(../../../assets/welcome/bg_welcome.jpg);
  background-position: center;
  background-size: cover;
  position: relative;

  > * {
    z-index: 2;
  }

  .gradient {
    background-image: linear-gradient(
      to top,
      $dark_color 0%,
      rgba($dark_color, 0) 100%
    );
    position: absolute;
    width: 100%;
    height: 55%;
    z-index: 1;
    bottom: 0;
  }

  .content {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    padding: 0 20px;

    .logo {
      position: absolute;
      top: 0;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 67px;
      margin-top: 20px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .container {
    background-image: url(../../../assets/welcome/bg_welcome_mobile.jpg);
    background-position: right;
  }
}
