@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: -22px 20px 24px;

  .discountBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    background: url('../../../assets/discount_bg.png') no-repeat
      center;

    svg {
      margin-top: 20px;
    }
  }

  .note {
    max-width: 480px;
    background-color: #E9DFF2;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    color: $dark_color;
    border-radius: 16px;
    padding: 20px 16px;
    margin: 16px 0 22px;

    .accent {
      color: #802DF6;
    }
  }

  .title {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;

    .accent {
      color: #802DF6;
    }
  }

  .stickyContainer {
    display: flex;
    flex-direction: column;

    .button {
      &.secondary {
        margin: 0;
        background: $white_color;
        color: $dark_color;
      }
    }
  }
}