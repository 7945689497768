@import 'styles/colors';

.App {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
}

.switch-wrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.AppContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $dark_color;
}

.grecaptcha-badge {
  z-index: 100;
}
