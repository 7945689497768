.template {
  h3 {
    text-align: left;
    margin: 22px 0 18px;
    font-size: 24px;
  }

  .listAccent {
    list-style-image: url(../../../assets/icon_check_2.svg);
    line-height: 18px;
    padding: 0;
    letter-spacing: 0.17px;
    margin: 30px 20px;

    &.listAccent {
      color: #1e122a;
      margin: 0 20px;
      font-weight: 500;

      li:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    li {
      padding-left: 15px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
