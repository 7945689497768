@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/colors';

.title {
  font-size: 29px;
  line-height: 32px;
}

> * {
  z-index: 2;
}

.arrowTitle {
  opacity: 0.5;
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  button {
    background-image: none;
    background-color: $white_color;
    color: $dark_color;

    &:not(:last-child) {
      margin-right: 9px;
    }
  }
}

.footer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;

  .links {
    display: flex;
    justify-content: center;

    a {
      @include noSelect;
      color: rgba($white_color, 0.8);
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.4px;
      padding: 5px 10px;
      text-decoration: none;
    }
  }

  .copyright {
    margin-top: 0;
    margin-bottom: 20px;
    color: rgba($white_color, 0.6);
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
}

.arrow {
  height: 20px;
  margin-top: 0;
  margin-bottom: 4px;
  opacity: 0.5;

  animation: sliding 1.5s infinite;
  -moz-animation: sliding 1.5s infinite;
  -webkit-animation: sliding 1.5s infinite;
  -o-animation: sliding 1.5s infinite;
}

/* Animation Keyframes*/
@keyframes sliding {
  0% {
    margin-top: 0;
    margin-bottom: 4px;
  }
  50% {
    margin-top: 4px;
    margin-bottom: 0;
  }
  100% {
    margin-top: 0;
    margin-bottom: 4px;
  }
}

@-moz-keyframes sliding {
  0% {
    margin-top: 0;
    margin-bottom: 4px;
  }
  50% {
    margin-top: 4px;
    margin-bottom: 0;
  }
  100% {
    margin-top: 0;
    margin-bottom: 4px;
  }
}

@-webkit-keyframes sliding {
  0% {
    margin-top: 0;
    margin-bottom: 4px;
  }
  50% {
    margin-top: 4px;
    margin-bottom: 0;
  }
  100% {
    margin-top: 0;
    margin-bottom: 4px;
  }
}

@-o-keyframes sliding {
  0% {
    margin-top: 0;
    margin-bottom: 4px;
  }
  50% {
    margin-top: 4px;
    margin-bottom: 0;
  }
  100% {
    margin-top: 0;
    margin-bottom: 4px;
  }
}
