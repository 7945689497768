@import 'styles/variables';
@import 'styles/colors';

.container {
  width: 100%;
  min-height: 78px;

  .inputContainer {
    display: flex;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 40px;
    height: 52px;

    input {
      &::placeholder {
        color: rgba($white_color, 0.3);
      }
    }

    .codeInput {
      background: transparent;
      height: auto;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: $white_color;

      &.invalid {
        color: #e34646;
      }
    }

    .applyButton {
      flex: 1;
      height: 40px;
      margin: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      padding: 9px 15px;

      &:disabled {
        background: linear-gradient(
          270deg,
          rgba(178, 80, 255, 0.3) 0%,
          rgba(128, 45, 246, 0.3) 100%
        );
      }
    }

    .deleteButton {
      margin: 13px 15px;
      cursor: pointer;
    }

    .checkMark {
      margin: 13px 15px;
    }
  }

  .error {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #e34646;
    margin: 0 20px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .container {
    min-height: 90px;

    .inputContainer {
      height: 60px;

      .applyButton {
        height: 46px;
        margin: 7px;
        padding: 9px 20px;
      }
    }
  }
}
