.container {
  background: url('../../../assets/bg_fog.png') center no-repeat;
  background-size: cover;
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .containerBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;

    &.yearly {
      background: url('../../../assets/bg_particles_blue.png') center no-repeat;
      background-size: contain;
    }

    &.lifetime {
      background: url('../../../assets/bg_particles_purple.png') center
        no-repeat;
      background-size: contain;
    }
  }

  .content {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    width: 100%;

    .logo {
      margin-top: 20px;
      align-self: center;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    opacity: 0.5;
    top: 5px;

    &.yearly {
      background: radial-gradient(
        41.98% 41.98% at 50% 50%,
        #2f57a5 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.lifetime {
      background: radial-gradient(
        41.98% 41.98% at 50% 50%,
        #662fa5 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .cardContainer {
    flex: 1;

    &.yearly {
      background: url('https://dz4buqwecyhdr.cloudfront.net/gift/yearly.png')
        no-repeat center;
      background-size: contain;
    }

    &.lifetime {
      background: url('https://dz4buqwecyhdr.cloudfront.net/gift/lifetime.png')
        no-repeat center;
      background-size: contain;
    }
  }
}
