@import 'styles/colors';

.links {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  a {
    color: rgba($dark_color, 0.5);
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.4px;
    padding: 10px;
    text-decoration: none;
  }
}

.copyright {
  margin-top: 10px;
  color: rgba($dark_color, 0.3);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  font-weight: 500;
  text-align: center;
}
