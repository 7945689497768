@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  // flex-grow: 1;
  margin: 24px 20px 20px;
  max-width: $max-content-width;
  align-items: center;
  gap: 20px;

  .image {
    margin: 20px 0 20px;
    align-self: flex-end;
    max-width: 100%;

    @media (max-width: $breakpoint-tablet) {
      margin-right: -20px;
    }
  }

  .content {
    .title {
      font-size: 28px;
      line-height: 36px;
      text-align: left;
      font-weight: 700;
      color: $dark_color;
      margin-bottom: 20px;
      align-self: start;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: rgba($dark_color, 0.64);
    }
  }

  .totalWrapper {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #f4f1e8;
    padding: 0;
    width: 100%;

    .priceWrapper {
      display: flex;
      gap: 10px;
    }

    .total {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-self: center;
    }

    .discount {
      display: flex;
      width: 50px;
      height: 27px;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      background: #ef5350;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 590;
      line-height: 24px;
      margin-top: 16px;
    }

    .oldPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a6a4a9;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      text-decoration: line-through;
      text-transform: uppercase;
    }

    .newPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  .stickyContainer {
    display: flex;
    gap: 16px;
    align-self: center;
    padding-top: 0px !important;
  }

  .greenButton {
    background: $active_green_color;
    color: $white_color;
    max-height: 48px;
    margin-top: 0px !important;
  }

  .grayButton {
    background: none;
    background-color: #f4f1e8;
    color: $dark_color;
    cursor: pointer;
    max-height: 48px;
    margin-top: 0px !important;
  }

  .button {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .note {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    color: rgba($dark_color, 0.4);
    margin: 0 20px;
  }
}
