@import 'styles/variables';
@import 'styles/colors';

.title {
  color: white;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.4px;
  margin: 40px 20px 18px;
  max-width: $max-content-width;

  .accent {
    color: #d295ff;
  }
}

input {
  background: rgba(255, 255, 255, 0.07);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: $white_color;

  &::placeholder {
    font-weight: normal;
  }
}

.submitButton {
  &:disabled {
    background: linear-gradient(
      270deg,
      rgba(178, 80, 255, 0.3) 0%,
      rgba(128, 45, 246, 0.3) 100%
    );
    color: rgba(255, 255, 255, 0.3);
  }
}

.helpSection {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.8);

  a {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
  }
}

@media (min-width: $breakpoint-tablet) {
  .title {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 52px;
  }
}
