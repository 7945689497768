@import 'styles/colors';

.line {
  font-size: 20px;
  line-height: 24px;
  color: $dark_color;
  margin-top: 12px;
  margin-bottom: 0;

  &.top22 {
    margin-top: 22px;
  }

  &.accent {
    color: $main_accent_color;
  }

  &.bold {
    font-weight: 700;
  }

  &.small {
    margin-top: 12px;
    font-size: 16px;
    margin-bottom: 14px;
  }
}
