@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  max-width: $max-content-width;
  align-self: center;
  flex: 1 1;
  align-items: stretch;
  padding: 0 20px;
  margin-bottom: 10px;
  transition: opacity 250ms ease-in;

  &.enter {
    opacity: 1;
  }

  &.leave {
    opacity: 0;
  }

  .content {
    flex: 1 1 auto;
  }

  .iconWrapper {
    display: flex;
    justify-content: center;

    .border {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: 64px;
      height: 64px;
      background-color: #F4F1E8;
      border-radius: 50%;
  
      .check {
        width: 23px;
        height: 23px;
    
        circle {
          fill: $active_green_color;
        }
      }
    }
  }

  .title {
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    color: $active_green_color;
    margin-top: 24px;
    margin-bottom: 0;

    &.accent {
      color: #B250FF;
    }
  }

  .description {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: rgba($dark_color, 0.64);
    text-align: center;
    margin-top: 16px;
  }

  .button {
    background: #F4F1E8;
    color: $dark_color;
  }
}