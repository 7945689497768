@import 'styles/colors';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: opacity 250ms ease-in;
  opacity: 0.01;
  padding: 0 20px;
  box-sizing: border-box;

  &.speed {
    transition: opacity 150ms ease-in;
  }

  &.enter {
    opacity: 1;
  }

  &.leave {
    opacity: 0;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $dark_color;
    margin-top: 24px;
    margin-bottom: 12px;

    &.titleWithMargin {
      margin-top: 43px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: $dark_color;
    margin: 0 0 22px;

    span {
      color: $main_accent_color;
    }
  }

  .option {
    background: $light_cream_color none;
    border-radius: 24px;
    height: auto;
    min-height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $dark_color;
    max-width: initial;
    margin-top: 10px;
    margin-bottom: 0;
    cursor: pointer;
    transition: 150ms all;
    padding: 12px;

    &.withSubText {
      height: 96px;
      border-radius: 20px;

      .text {
        font-weight: 600;
      }

      .subtext {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba($dark_color, 0.64);
        margin-top: 4px;
      }

      &.multiple {
        background-position: 98% 10px;

        &.active {
          background-position: 98% 10px;
        }
      }
    }

    &.multiple {
      background: $light_cream_color
        url(../../../../assets/ic_option_checkmark_empty.svg) no-repeat
        right 15px center;

      &.active {
        background: $active_green_color
          url(../../../../assets/ic_option_checkmark_checked.svg)
          no-repeat right 15px center;
      }
    }

    &.active {
      background: $active_green_color none;
      color: $white_color;

      .subtext {
        font-weight: 600;
        color: rgba($white_color, 0.64);
      }
    }
  }
  
  .button {
    height: 52px;
    min-height: 52px;
    margin-bottom: 20px;
  }
}
