@import 'styles/colors';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;

  .title {
    text-align: center;
    margin: 30px 20px 10px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: $dark_color;
  }

  .description {
    color: rgba($dark_color, 0.6);
    margin: 10px 30px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  .button {
    width: calc(100% - 40px);
    height: 52px;
    min-height: 52px;
    margin: 10px 20px 20px;
  }
}
