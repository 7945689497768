@import 'styles/colors';

.lineContainer {
  flex: 1;
  height: auto;

  .stepLabel {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $dark_color;
    opacity: 0.5;
    margin-bottom: 14px;
    margin-left: -20px;
    margin-right: -20px;
  }

  &.blueColor,
  &.purpleColor,
  &.greenColor {
    .line {
      background-color: rgba($white_color, 0.5);
      margin-left: 0;
      margin-right: 0;

      .lineProgress {
        background-color: $white_color;
      }
    }

    .stepLabel {
      color: $white_color;
      opacity: 0.75;
    }
  }
}

.line {
  flex: 1;
  height: 4px;
  background-color: $light_violet_color;
  margin-bottom: 11px;
  position: relative;
  margin-right: -2px;
  margin-left: -2px;

  &.blueColor,
  &.purpleColor,
  &.greenColor {
    margin-right: 0;
    margin-left: 0;
    background-color: rgba($white_color, 0.5);

    &.lineActive {
      background-color: $white_color;
    }
  }

  .lineProgress {
    position: absolute;
    height: 4px;
    top: 0;
    left: 0;
    background-color: $main_accent_color;
    border-radius: 0 6px 6px 0;
    transition: width 0.5s;
  }

  &.lineActive {
    background-color: $main_accent_color;
  }
}
