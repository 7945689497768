@import 'styles/variables';

.loadingButton {
  width: initial;
}

@media (min-width: $breakpoint-tablet) {
  .loadingButton {
    width: 250px !important;
  }
}
