@import 'styles/colors';

.input[type='text'] {
  height: 32px;
  font-size: 12px;
  color: rgb(84, 84, 87);
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.2px;
  background-color: $white_color;
  border: none;
  border-radius: 8px;
  margin: 5px 0;
  padding: 0 8px;
  caret-color: rgba($dark_color, 0.64);
  font-family: 'Arial';

  &::placeholder {
    font-family: 'Arial';
    color: grey;
    font-size: 12px;
    font-weight: 500;
  }

  &.validationError {
    color: #ef5350;
  }

  &:focus {
    outline: none;
  }
}

.label {
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  color: rgba($dark_color, 0.64);
  white-space: nowrap;
}

.formError {
  font-size: 8px;
  line-height: 10px;
  color: #ef5350;
  margin-left: 2px;
}
