@import 'styles/variables';
@import 'styles/colors';

.card {
  max-width: 400px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.4px;
  color: $white_color;

  .yearly {
    color: #fb9a53;
  }

  .lifetime {
    color: #d295ff;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $white_color;
  opacity: 0.8;
}

.planCards {
  width: 100%;
  display: flex;
}

.inputContainer {
  position: relative;
  display: flex;
  width: 100%;

  .input {
    flex: 1;
    padding: 0 60px 0 20px;
    background: rgba($white_color, 0.07);
    color: white;
    font-weight: 600;

    &::placeholder {
      color: rgba($white_color, 0.5);
      font-weight: normal;
    }
  }

  svg {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto 0;
  }
}

.emailDescription {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: rgba($white_color, 0.5);
  align-self: flex-start;
  margin: 0 20px;
}

.quantityContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 14px;

  .quantityTitle {
    flex: 2;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: rgba($white_color, 0.5);
    margin-left: 20px;
  }

  .quantityAmount {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-left: 15px;
    margin-right: 15px;
  }

  .quantityButton {
    background: rgba($white_color, 0.1) none;
    width: 41px;
    height: 41px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

.divider {
  background-color: rgba($white_color, 0.2);
  width: 100%;
  height: 1px;
}

.totalContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 25px;

  .totalTitle {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: rgba($white_color, 0.5);
    margin-left: 20px;
  }

  .totalAmount {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-right: 20px;
  }
}

.submitButton {
  margin-bottom: 20px;

  &:disabled {
    background: linear-gradient(
      270deg,
      rgba(178, 80, 255, 0.3) 0%,
      rgba(128, 45, 246, 0.3) 100%
    );
    color: rgba($white_color, 0.3);
  }
}

@media (min-width: $breakpoint-tablet) {
  .title {
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    margin-top: 0;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
  }

  .faq {
    padding-bottom: 0;
  }
}
