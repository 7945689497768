@import 'styles/colors';

.alert-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($dark_color, 0.4);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: unset;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .alert {
    padding: 52px 20px;
    background: white;
    border-radius: 16px;
    margin: 20px;
    text-align: center;

    h3 {
      font-size: 20px;
    }

    p {
      color: rgba($dark_color, 0.6);
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.2px;
    }

    .inverted {
      margin: 0;
    }
  }
}
