@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  gap: 16px;

  .title {
    margin: 0;
    font-size: 22px;
    line-height: 26px;
  }
}

.submit {
  background-image: none;
  background-color: #80ba86 !important;
  margin-top: 0px;
}

.container {
  width: $max-content-width;
  max-width: 100%;
  min-width: 335px;
  box-sizing: border-box;
  border-radius: 16px;
  align-self: center;
  position: relative;
  padding: 40px 16px 20px;
  border: 3px solid rgba(#a6a4a9, 0.2);
  cursor: pointer;

  &.selected {
    border: 3px solid #80ba86;
  }

  &.multiple {
    background: $white_color url(../../../../assets/ic_empty_check_v2.svg)
      no-repeat left 15px center;

    &.selected {
      background: #f9fefa url(../../../../assets/ic_check_green.svg) no-repeat
        left 15px center;
    }
  }

  .label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 13px;
    border-top-right-radius: 13px;
    padding: 2px 12px 4px;
    background-color: #80ba86;
    outline: 2px solid #80ba86;
    color: $white_color;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-top: -10px;
    opacity: 0.64;

    .term {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;

      &.disabled {
        color: #a6a4a9;
      }

      .discount {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        max-width: max-content;
        font-size: 10px;
        line-height: 12px;
        font-weight: 600;
        margin-top: 4px;

        .new {
          border-radius: 4px;
          color: #a6a4a9;

          &.selected {
            color: #090110;
          }
        }

        .old {
          text-decoration: line-through;
          color: #a6a4a9;

          &.selected {
            color: #090110;
          }
        }
      }
    }

    .button {
      height: 52px;
      min-height: 52px;
      margin-bottom: 20px;
    }

    .price {
      display: flex;

      .currency {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        margin-top: -2px;
        margin-right: 4px;

        .priceLabel {
          font-size: 10px;
          line-height: 12px;
          color: #a6a4a9;
          font-weight: 600;
          text-decoration: line-through;
        }

        .currencySymbol {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
          font-style: Semibold;
        }
      }

      .firstSymbol {
        font-size: 48px;
        line-height: 56px;
        font-weight: 500;
        margin-top: -9px;
      }

      .otherPrice {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: -2px;
        margin-left: 4px;

        .priceLabel {
          font-size: 10px;
          line-height: 12px;
          color: #a6a4a9;
          font-weight: 600;

          &.selected {
            color: rgba(#090110, 0.64);
          }
        }

        .otherPriceSymbols {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }
  }
}
