@import 'styles/colors';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  &.v2 {
    .percent {
      color: $main_accent_color;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .progress {
      background-color: $main_accent_color;
    }
  }

  .percent {
    font-size: 14px;
    line-height: 18px;
    color: $white_color;
    font-weight: 500;
    justify-content: center;
    margin-bottom: 5px;
  }
}

.background {
  width: 140px;
  height: 4px;
  border-radius: 9px;
  overflow: hidden;
  background-color: rgba($white_color, 0.3);

  .progress {
    opacity: 1;
    height: 100%;
    transition: 0.2s width linear;
    background-color: #d295ff;
  }
}
