@import 'styles/colors';

.stickyContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: sticky;
  bottom: -1px;
  background: linear-gradient(
    to top, 
    $white_color,
    $white_color 50%, 
    $white_color 57%, 
    rgba($white_color, 0) 100%
  );
  padding-top: 20px
}
