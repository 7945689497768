.error {
  display: flex;
  align-items: center;
  padding: 20px 27px;
  background: white;
  color: #000000;
  border-radius: 16px;
  top: 0;
  z-index: 3;
  opacity: 1;
  position: fixed;
  box-shadow: 0 4px 15px rgba(184, 184, 184, 0.35);
  margin: 20px;
  left: 0;
  right: 0;
  transition: opacity 0.3s;

  &.hidden {
    pointer-events: none;
    opacity: 0;
    top: -200px;
  }

  .icon-wrapper {
    width: 46px;
    height: 46px;
    background-color: rgba(227, 70, 70, 0.1);
    border-radius: 50px;
    display: flex;
    margin-right: 26px;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
    }
  }

  .error-content {
    flex: 1;
    word-break: break-word;
    overflow: auto;
    overflow-wrap: anywhere;
  }
}
