@import 'styles/colors';

$main_color_light: $dark_color;
$main_color_dark: $white_color;

$main_color: var(--main-color);
$placeholder_color: var(--placeholder-color);
.container {
  --main-color: #{$main_color_light};
  --placeholder-color: #{rgba($main_color_light, 0.5)};

  .dark {
    --main-color: #{$main_color_dark};
    --placeholder-color: #{rgba($main_color_dark, 0.5)};
  }
}

.markets {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: var(--placeholder-color);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-top: 30px;

  .market {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 10px;
    }

    &.google {
      margin-right: 33px;
    }

    &.apple {
      img {
        height: 52px;
      }
    }
  }
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;

  .apple {
    background-color: rgba(137, 201, 71, 0.1);
    border-radius: 51px;
    padding: 10px 17px;
    margin-left: 13px;
    color: rgba(137, 201, 71, 0.6);
    font-weight: 500;

    span {
      font-weight: 600;
      color: rgba(137, 201, 71, 1);
    }
  }
}

.metrics {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--placeholder-color);
  font-weight: 500;
  margin-top: 23px;

  b {
    font-weight: 500;
    color: var(--main-color);
  }

  .verticalDivider {
    width: 30px;
  }
}

