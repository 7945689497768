@import 'styles/colors';

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.5s;
  background-color: $white_color;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: stretch;

  &.dark {
    background-color: $dark_purple_color;

    .image {
      background: url('../../../assets/error.svg') no-repeat center center;
      background-size: contain;
    }

    .content {
      .title {
        color: $white_color;
      }

      .description {
        color: rgba($white_color, 0.5);
      }
    }
  }

  .image {
    background: url('../../../assets/error.svg') no-repeat center center;
    background-size: contain;
    flex: 1;
    margin: 20px 0;
  }

  .content {
    margin-left: 20px;
    margin-right: 20px;

    .title {
      margin: 0 20px 14px;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.6px;
      color: $dark_color;
    }

    .description {
      margin: 0 20px 15px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: rgba($dark_color, 0.5);
    }

    .button {
      margin-bottom: 20px;
    }
  }
}
