@import 'styles/colors';
@import 'styles/variables';

label {
  display: flex;
}

.reasonTemplate {
  h3 {
    text-align: center;
    font-size: 20px;
  }
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  margin: 0 20px 40px;
  text-align: left;

  &.bottom90 {
    margin-bottom: 90px;
  }

  .title {
    text-align: center;
    color: rgba($dark_color, 0.5);
    margin-bottom: 23px;
    letter-spacing: 0.2px;

    h3 {
      color: rgba($dark_color, 1);
      margin-top: 25px;
      margin-bottom: 7px;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.6px;
    }
  }

  .leftTitle {
    text-align: center;
    margin: 15px 0;
    font-size: 20px;
  }

  .total {
    display: flex;
    margin: 13px 0;

    &.bottom9 {
      margin: 13px 0 9px;
    }

    &.top0 {
      margin: 0 0 13px;
    }

    span {
      margin-left: auto;
      text-align: right;
      color: $dark_color;
      line-height: 22px;
      letter-spacing: 0.2px;

      &.purpleBadge {
        background: $gradient;
        border-radius: 7px;
        color: $white_color;
        font-weight: 600;
        font-size: 14.1818px;
        line-height: 26px;
        margin-left: 0;
        letter-spacing: 0.236364px;
        padding: 0 8px;
        text-align: left;
      }

      &.accent {
        color: #c68bfd;
      }

      &.light {
        color: rgba($dark_color, 0.3);
      }

      &.grey {
        color: rgba($dark_color, 0.6);
      }

      &.bold {
        font-weight: 500;
      }
    }
  }

  .promocode {
    display: flex;
    flex-direction: column;

    input {
      font-family: Euclid Circular A;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      background-color: transparent;
      border: none;
      border-radius: 40px;
      padding: 0 20px;
      caret-color: $secondary_accent_color;

      &:focus {
        outline: none;
      }
    }

    .promocode-btn {
      display: flex;
      justify-content: space-between;
      border: none;
      border-radius: 50px;
      color: white;
      background-color: rgba($dark_color, 0.07);
      padding: 0;
      margin-top: 15px;
      height: 52px;

      button {
        width: 86px;
        height: 40px;
        padding: 9px 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.2px;
        margin: 6px;

        &:disabled {
          background-image: linear-gradient(
            to right,
            $main_accent_color 0%,
            $secondary_accent_color 100%
          );
          opacity: 0.7;
        }

        &.promo-button-applied {
          transition: width 0.3s ease;
          width: 40px;
        }
      }
    }

    .promo-status-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.2px;
      margin: 0 20px;

      &.applied {
        color: #b350ff;
      }

      &.invalid {
        color: #e34646;
      }
    }
  }

  .payment-check-list {
    margin: 6px 0 10px 20px;
    font-size: 12px;
    line-height: 19px;

    li:not(:last-child) {
      margin-bottom: 7px;
    }
  }
}

.payment {
  background-repeat: no-repeat;
  max-width: $max-content-width;  
  margin: 0 auto;
  width: 100%;
}

.review-container {
  margin-bottom: 25px;
}

.footer {
  margin-top: 30px;
}
