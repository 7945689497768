@import 'styles/colors';

.graphsContainer {
  display: flex;
  align-items: baseline;
  margin-top: 32px;
  max-width: min-content;

  > div {
    margin: 0 10px;
  }

  .graph {
    display: flex;
    flex-direction: column;
    width: min-content;

    .trialGraph {
      width: 120px;
      height: 138px;
      border-radius: 6px;
      background: linear-gradient(
        270deg,
        rgba(178, 80, 255, 0.3) 0%,
        rgba(128, 45, 246, 0.3) 100%
      );
    }

    .skipTrialGraph {
      width: 120px;
      height: 240px;
      background: $gradient;
      border-radius: 6px;
      position: relative;

      .percent {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        color: $white_color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .caption {
      font-size: 16px;
      line-height: 24px;
      color: $dark_color;
      opacity: 0.6;
      text-align: center;
      margin-top: 12px;
      white-space: pre-wrap;
    }
  }
}
