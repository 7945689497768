@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: $max-content-width;
  align-self: center;
  flex: 1 0;
  margin: 40px 20px 60px;

  .logo {
    width: 58px;
    height: 20px;
    position: absolute;
    top: 20px;

    path {
      fill: #1b1b1b;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.2px;
      margin-top: 23px;
    }

    .description {
      max-width: 300px;
      color: rgba($dark_color, 0.8);
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 36px;
    }

    .note {
      max-width: 250px;
      text-align: center;
      font-size: 16px;
      color: rgba($dark_color, 0.6);
      margin-bottom: 12px;
      letter-spacing: 0.2px;
      line-height: 22px;
    }

    .markets {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: var(--placeholder-color);
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      gap: 10px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $dark_color;
        border-radius: 10px;
        cursor: pointer;

        &:hover,
        :active {
          opacity: 0.8;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}
