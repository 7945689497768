@import 'styles/colors';
@import 'styles/variables';

.note {
  margin: 32px 0px 22px;
  padding: 8px 16px 8px 32px;
  background-color: $light_cream_color;
  border-radius: 16px;
  max-width: $max-content-width;
  color: rgba($dark_color, 0.6);
  z-index: -2;

  &.st_v3 {
    background-color: $white_color;
    padding: 0;
    margin-top: 22px;

    .title {
      width: 100%;
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      text-align: left;
      color: $dark_color;
      margin-bottom: 12px;
    }

    ol {
      li {
        margin-left: 20px;

        &::before {
          background-color: #E9DFF2;
          color: $main_accent_color;
        }
      }
    }
  }

  &.noteWithoutRefund {
    padding: 24px 16px;
  }

  ol {
    padding-left: 12px;

    li {
      margin: 0;
      padding: 0;
      margin-top: 16px;
      list-style: none;
      position: relative;

      span {
        margin-right: 10px;
      }

      &::before {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        position: absolute;
        left: -29px;
        top: 2px;
        background-color: #b1afa9;
        z-index: -1;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 500;
        color: $white_color;
        content: counter(list-item);
      }
    }
  }

  .ebookNote {
    margin-top: 30px;
  }
}
