@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid rgba(#a6a4a9, 0.2);
  border-radius: 12px;

  .title {
    text-align: center;
    margin-bottom: 50px;
  }
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -5px;

  .mastercard {
    margin-top: 3px;
  }

  .amex {
    margin-left: -5px;
  }
}
