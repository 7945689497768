@import 'styles/mixins';
@import 'styles/colors';

.container {
  border-radius: 12px;
  position: relative;
  display: flex;
  height: 52px;

  &.dark {
    background: unset;

    .buttonBackground {
      background-color: unset;
    }

    .buttons {
      .button {
        background-color: rgba($white_color, 0.07);

        &.card {
          background-color: $white_color;
        }
      }
    }
  }

  .buttonBackground {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 6px;

    &.left {
      left: 0;
      right: 50%;
      @include animation('sliding-left .4s');

      @include keyframes(sliding-left) {
        0% {
          right: 0;
          left: 50%;
        }
        50% {
          right: 0;
          left: 0;
        }
        100% {
          right: 50%;
          left: 0;
        }
      }
    }

    &.right {
      left: 50%;
      right: 0;
      @include animation('sliding-right .4s');

      @include keyframes(sliding-right) {
        0% {
          right: 50%;
          left: 0;
        }
        50% {
          right: 0;
          left: 0;
        }
        100% {
          right: 0;
          left: 50%;
        }
      }
    }
  }

  .buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    .button {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      cursor: pointer;
      border: 2px solid #ebebec;
      border-radius: 12px;
      min-width: 80px;
      box-sizing: border-box;

      &.active {
        background-color: rgba(128, 45, 246, 0.08);
        border: 2px solid $main_accent_color;
      }

      .paymentType {
        object-fit: contain;
        width: auto;

        &.maestro {
          width: 20px;
        }

        &.amex {
          width: 16px;
        }

        &.visa {
          @media (max-width: 400px) {
            max-width: 21px;
          }
        }

        &.mastercard {
          @media (max-width: 400px) {
            max-width: 15px;
          }
        }

        &.paypal {
          @media (max-width: 400px) {
            max-width: 54px;
          }
        }

        &.applePay {
          @media (max-width: 400px) {
            max-width: 33px;
          }
        }

        &:not(:last-child) {
          margin-right: 5px;

          @media (max-width: 400px) {
            margin-right: 0;
          }
        }
      }

      .paymentPaypal {
        height: 20px;
      }

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
}
