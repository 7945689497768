@import 'styles/colors';

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($dark_color, 0.4);
  z-index: 99;
  opacity: 1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: unset;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .modal {
    padding: 10px 20px 5px;
    background: white;
    border-radius: 16px;
    margin: 20px;
    transition: all 0.2s, padding 0s;
  }
}
