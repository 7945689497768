.error {
  display: flex;
  align-items: center;
  padding: 20px 27px;
  background: white;
  border-radius: 16px;
  top: 0;
  z-index: 20;
  opacity: 1;
  position: fixed;
  box-shadow: 0 4px 15px rgba(184, 184, 184, 0.35);
  margin-top: 20px;
  left: 20px;
  right: 20px;
  transition: opacity 0.3s;
  color: black;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  &.hidden {
    pointer-events: none;
    opacity: 0;
    top: -200px;
  }

  .icon-wrapper {
    width: 46px;
    height: 46px;
    background-color: rgba(227, 70, 70, 0.1);
    border-radius: 50px;
    display: flex;
    margin-right: 26px;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
    }
  }

  .error-content {
    flex: 1;
    word-break: break-word;
    overflow: auto;
    overflow-wrap: anywhere;
  }
}
