@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  height: 100vh;
  overflow-y: hidden;

  .image {
    max-width: 100%;
    align-self: center;
    object-fit: cover;
    height: 65vh;
  }

  .content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    max-width: $max-content-width;
    align-self: center;
    flex: 1;
    padding: 0 20px;

    .label {
      font-size: 16px;
      line-height: 24px;
      color: rgba($dark_color, 0.55);
      font-weight: 500;
    }

    .title {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      color: $dark_color;
      margin-top: 4px;
    }

    .description {
      max-width: 450px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba($dark_color, 0.64);
      text-align: center;
      margin: 12px 0 10px;
    }

    .note {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      color: rgba($dark_color, 0.4);
      margin: 10px 0 17px;
      white-space: pre-wrap;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .container {
    .image {
      height: 50vh;
      margin-top: 0;
    }
  }
}

@media (max-height: 630px) {
  .container {
    .image {
      height: 47vh;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
