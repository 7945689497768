@import 'styles/mixins';
@import 'styles/colors';

.cookieConsent {
  width: 335px;
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  opacity: 1;
  @include transition(opacity 1s ease);
  background-color: $white_color;
  box-shadow: 0px 4px 40px rgba($dark_color, 0.25);
  border-radius: 16px;
  box-sizing: border-box;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .textWrapper {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    font-weight: 400;
    color: $dark_color;
    margin: 16px 20px 2px;
    white-space: pre-wrap;
    text-align: left;

    .accent {
      color: $main_accent_color;
    }

    .bold {
      font-weight: 600;
    }

    a {
      color: $white_color;
      text-decoration: none;
    }
  }

  button {
    width: 120px;
    height: 36px;
    background: transparent;
    border: 2px solid $main_accent_color;
    color: $main_accent_color;
    cursor: pointer;
    margin-bottom: 20px;
  }
}

@media (max-width: 740px) {
  .cookieConsent {
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
