@import 'styles/colors';

.modal {
  max-width: 335px;
  box-sizing: border-box;
}

.prompt {
  .title {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin-top: 12px;
  }
  
  .description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 16px 0 22px;
    text-align: center;
  }
  
  .buttonContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  
    button {
      font-weight: 400;
    }
  
    .outlined {
      background: $white_color;
      color: $dark_color;
      border: 2px solid $secondary_accent_color;
    }
  }
}