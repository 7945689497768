.totalHeader {
  display: flex;
  margin-top: 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

@import 'styles/colors';

.total {
  display: flex;
  margin-top: 20px;

  &.renews {
    margin-top: 6px;
  }

  span {
    margin-left: auto;
    text-align: right;
    color: $dark_color;
    line-height: 22px;
    letter-spacing: 0.2px;

    &.purpleBadge {
      background: $gradient;
      border-radius: 7px;
      color: $white_color;
      font-weight: 600;
      font-size: 14.1818px;
      line-height: 26px;
      margin-left: 0;
      letter-spacing: 0.236364px;
      padding: 0 8px;
      text-align: left;
    }

    &.grey {
      color: rgba($dark_color, 0.6);
    }

    &.bolder {
      font-weight: 500;
    }

    &.accent {
      color: #c68bfd;
    }

    &.bold {
      font-weight: 600;
    }
  }
}
