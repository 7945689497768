@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/colors';

.item {
  max-width: 840px;
  background: $light_cream_color;
  border-radius: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  @include noSelect;

  .questionContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;

    .question {
      color: $dark_color;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    svg {
      &:hover,
      :active {
        opacity: 0.7;
      }
    }
  }

  .answer {
    color: rgba($dark_color, 0.64);
    transition: max-height 0.6s ease;
    text-align: left;
    overflow: hidden;
    margin: 0 16px;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}
