@import 'styles/colors';
@import 'styles/variables';

.trialOffer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: $white_color;
  overflow-y: scroll;
  transition: opacity 0.5s;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.hidden {
    display: none;
    pointer-events: none;
    opacity: 0;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: $max-content-width;
    align-self: center;
    flex: 1 0 auto;
    margin: 20px 20px 34px;

    .logo {
      width: 58px;
      height: 20px;

      path {
        fill: #1b1b1b;
      }
    }
  }
}
