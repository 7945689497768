@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  touch-action: none;

  .image {
    max-width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .content {
    max-width: $max-content-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 2;
    position: fixed;
    bottom: 0;

    .title {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      font-weight: 700;
      margin: 0 12px 12px;

      .accent {
        color: $secondary_accent_color;
      }
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
      color: rgba($dark_color, 0.64);
      text-align: center;
      margin-bottom: 10px;
      font-weight: 500;
      white-space: pre-wrap;

      .accent {
        color: $dark_color;
        font-weight: 600;
      }
    }

    .button {
      min-height: 52px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    .content {
      .title {
        font-size: 28px;
        line-height: 34px;
        white-space: pre-wrap;
      }
      .subtitle {
        font-size: 14px;
        line-height: 22px;
        white-space: unset;
      }
    }
  }
}

@media (min-width: $max-content-width) {
  .wrapper {
    .image {
      max-width: 100%;
      height: 65vh;
      object-fit: cover;
    }
  }
}

@media (max-height: 630px) {
  .wrapper {
    .image {
      height: 45vh;
    }
  }
}
