@import 'styles/colors';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 26px 0 10px;
  padding: 0 20px;
  box-sizing: border-box;

  .content {
    flex: 1 1 auto;
    display: flex;
    position: relative;

    .innerContent {
      overflow-y: auto;
      flex: 1 1 auto;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .bottomGradient {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      background: linear-gradient(
        180deg,
        rgba($white_color, 0) 0%,
        $white_color 100%
      );
    }
  }

  .title {
    text-align: center;
    margin-top: 0;
  }

  .graph {
    width: 100%;
    height: auto;
  }

  .info {
    display: flex;
    align-items: flex-start;
    margin-top: 25px;
    margin-bottom: 10px;

    .description {
      color: $dark_color;
      margin: 0 20px;
      letter-spacing: 0.4px;
      font-style: normal;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .button {
    max-width: 100%;
    height: 52px;
    min-height: 52px;
  }
}
