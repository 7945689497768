@import 'styles/colors';

.header {
  display: flex;
  flex: 2 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  img {
    width: 100%;
    height: 100%;
  }

  .title {
    text-align: center;
    color: rgba($dark_color, 0.5);
    margin-bottom: 23px;
    letter-spacing: 0.2px;

    h3 {
      color: rgba($dark_color, 1);
      margin-top: 25px;
      margin-bottom: 7px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.6px;
    }
  }

  .graphDescription {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $grey_color;
    margin-top: 15px;
    margin-bottom: 30px;
  }
}
