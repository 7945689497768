@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 30px;
  box-sizing: border-box;
  transition: opacity 250ms ease-in;

  &.speed {
    transition: opacity 150ms ease-in;
  }

  &.enter {
    opacity: 1;
  }

  &.leave {
    opacity: 0;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $dark_color;
    margin-top: 0;
  }

  .statement {
    margin: 28px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
  }

  .note {
    display: flex;
    width: 100%;
    justify-content: space-between;

    > p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgba($dark_color, 0.64);
      margin-top: 2px;
    }
  }

  .options {
    &.scale {
      display: flex;
      grid-gap: 9px;
      margin-top: 0;
      align-items: center;
      justify-content: center;

      .option {
        background: $light_cream_color none;
        border-radius: 12px;
        max-width: initial;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: $dark_color;
        margin-top: 0;
        cursor: pointer;
        transition: 150ms all;
        padding: 0;
        
        @media (min-width: 430px) {
          min-height: 70px;
        }

        @media (min-width: 440px) {
          min-height: 80px;
        }

        &.active {
          background: $active_green_color;
          color: $white_color;
        }
      }
    }
  }

  .option {
    background: $light_cream_color none;
    border-radius: 40px;
    height: 52px;
    min-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $dark_color;
    max-width: initial;
    margin-top: 0;
    cursor: pointer;
    transition: 150ms all;
    padding: 0 40px;

    &.multiple {
      background: $light_cream_color
        url(../../../assets/ic_option_checkmark_empty.svg) no-repeat
        right 15px center;

      &.active {
        background: $active_green_color
          url(../../../assets/ic_option_checkmark_checked.svg)
          no-repeat right 15px center;
      }
    }

    &.active {
      background: $active_green_color none;
      color: $white_color;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .button {
    max-width: 100%;
    height: 52px;
    min-height: 52px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .container {
    .title {
      white-space: pre-line;
    }
  }
}
