.secure-server {
  display: flex;
  align-items: center;

  img {
    object-fit: scale-down;
    height: 13px;
  }

  .text {
    font-weight: 600;
    font-size: 13.4957px;
    line-height: 29px;
    letter-spacing: 0.506087px;
    color: #aaa6ab;
    margin-left: 5px;
  }
}
