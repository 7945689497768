@import 'styles/colors';

.dot {
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background-color: $light_violet_color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $main_accent_color;
  }

  &.blueColor,
  &.purpleColor,
  &.greenColor {
    background-color: rgba($white_color, 0.5);

    &.dotFilled {
      background-color: $white_color;
    }
  }

  &.blueColor {
    &.dotChecked {
      background: $white_color url(../../../../../assets/onboarding/blue_check.svg)
        top 9px left 6px no-repeat;
    }

    .label {
      color: #77a1cd;
    }
  }

  &.purpleColor {
    &.dotChecked {
      background: $white_color url(../../../../../assets/onboarding/purple_check.svg)
        top 9px left 6px no-repeat;
    }

    .label {
      color: #9277cd;
    }
  }

  &.greenColor {
    &.dotChecked {
      background: $white_color url(../../../../../assets/onboarding/green_check.svg)
        top 9px left 6px no-repeat;
    }

    .label {
      color: #67c3ad;
    }
  }

  &.dotChecked {
    background: $main_accent_color
      url(../../../../../assets/onboarding/ic_check.svg) top 9px left 7px
      no-repeat;
  }

  &.dotFilled {
    background: $main_accent_color;
  }
}
