@import 'styles/colors';

.container {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  height: 50px;
  margin: 14px 0 26px;
  padding: 0 20px;
  box-sizing: border-box;

  &.top50 {
    margin-top: 50px;
  }
}
