@import 'styles/variables';
@import 'styles/colors';

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.4px;
  color: white;
  margin-bottom: 4px;
  white-space: pre-wrap;

  .yearly {
    color: #fb9a53;
  }

  .lifetime {
    color: #d295ff;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba($white_color, 0.8);
  margin-bottom: 25px;
}

.planCards {
  width: 100%;
  display: flex;
}

.faq {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2px;
  color: rgba($white_color, 0.4);
  padding-bottom: 20px;
  font-weight: 500;

  a {
    color: #d295ff;
    font-weight: 500;
  }
}

@media (min-width: $breakpoint-tablet) {
  .title {
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
  }

  .faq {
    padding-bottom: 0;
  }
}
