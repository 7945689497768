.recurlySecured {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  flex-direction: column;
  align-items: center;
  
  .sslEncrypted {
    height: 25px;
    margin-right: 24px;
  }

  .poweredByRecurly {
    height: 12px;
    margin-left: 23px;
    margin-top: 4px;
  }
}