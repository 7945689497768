.container {
  background: url('../../../assets/bg_fog_transparent.png') center no-repeat;
  background-size: cover;
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .particles {
    width: 100%;
    height: 100%;
    z-index: 2;

    &.yearly {
      background: url('../../../assets/bg_particles_big_blue.png') center
        no-repeat;
      background-size: cover;
    }

    &.lifetime {
      background: url('../../../assets/bg_particles_big_purple.png') center
        no-repeat;
      background-size: cover;
    }
  }

  .content {
    z-index: 2;
  }

  .overlay {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;

    &.yearly {
      background: radial-gradient(
        41.98% 41.98% at 50% 50%,
        #2f57a545 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.lifetime {
      background: radial-gradient(
        41.98% 41.98% at 50% 50%,
        #662fa545 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .gradient {
    mix-blend-mode: darken;
    position: absolute;
    z-index: 1;

    &.start {
      background: linear-gradient(90deg, #0b0216 0%, rgba(11, 2, 22, 0) 100%);
      width: 20%;
      height: 100%;
      left: 0;
    }

    &.end {
      background: linear-gradient(270deg, #0b0216 0%, rgba(11, 2, 22, 0) 100%);
      width: 20%;
      height: 100%;
      right: 0;
    }
  }
}
