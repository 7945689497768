@import 'styles/color_themes';
@import 'styles/colors';
@import 'styles/variables';

button {
  background-image: linear-gradient(
    to right,
    $main_accent_color 0%,
    $secondary_accent_color 100%
  );
  border: none;
  border-radius: 50px;
  color: white;
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  outline: none;
  max-width: $max-content-width;
  width: 100%;
  height: 52px;
  font-family: 'Euclid Circular A';

  &.inverted {
    background-image: none;
    background-color: transparent;
    color: $secondary_accent_color;
    border: 1px solid #d5adf9;
  }

  &:disabled {
    background-image: none;
    background-color: rgba($dark_color, 0.2);
  }
}

@include dark {
  button {
    background-image: linear-gradient(
      270deg,
      $main_accent_color 0%,
      $main_accent_color 100%
    );

    &:disabled {
      background-image: none;
      background-color: rgba(207, 205, 208, 0.4);
    }
  }
}

h1 {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 32px;

  span {
    &.accent {
      color: #d295ff;
    }
  }
}

input[type='email'],
input[type='password'],
input[type='text'] {
  margin: 5px 0;
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  height: 52px;
  background-color: rgba($dark_color, 0.07);
  border: none;
  border-radius: 40px;
  padding: 0 20px;
  caret-color: $main_accent_color;
  transition: 0.2s;
  color: rgba($dark_color, 0.5);
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
  }

  &.invalid {
    color: #e34646;
    background-color: rgba(227, 70, 70, 0.1);
  }

  &::placeholder {
    color: rgba($dark_color, 0.5);
    font-weight: normal;
  }
}
