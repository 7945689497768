@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #190531 32%,
    $dark_purple_color 100%
  );
  position: relative;
  transition: opacity 250ms ease-in;
  opacity: 0.01;
  width: 100%;

  &.speed {
    transition: opacity 150ms ease-in;
  }

  &.greenColor,
  &.blueColor,
  &.purpleColor {
    .fog {
      background: none;
      opacity: 1;
    }

    .background {
      background: none;
    }

    .title {
      font-size: 32px;
      line-height: 36px;
      font-weight: 700;
    }

    .description {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
    }

    button {
      background-color: $white_color;
      background-image: none;
      color: $dark_color;
    }

    .topSpacer {
      height: 20px;
    }
  }

  &.greenColor {
    .fog {
      background-color: #67c3ad;
    }
  }

  &.blueColor {
    .fog {
      background-color: #77a1cd;
    }
  }

  &.purpleColor {
    .fog {
      background-color: #9277cd;
    }
  }

  &.enter {
    opacity: 1;
  }

  &.leave {
    opacity: 0;
  }

  .logo {
    align-self: center;
    position: absolute;
    top: 20px;
    z-index: 5;

    path {
      width: 58px;
      height: 20px;
      fill: $white_color;
    }
  }

  .innerContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    width: 100%;
    align-self: center;
    padding: 0 18px;
    box-sizing: border-box;
    max-width: $max-content-width;
  }

  .background {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: url(../../../assets/onboarding/bg_particles.svg) no-repeat
      center;
    background-size: auto 100%;
    z-index: 2;
  }

  .fog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: url(../../../assets/onboarding/bg_fog.png) no-repeat;
    background-size: cover;
  }

  .topSpacer {
    height: 60px;
    width: 100%;
  }

  .icon {
    height: 60px;
    width: 60px;
    background-color: rgba($white_color, 0.1);
    border-radius: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: $white_color;
    margin: 20px 26px 17px 0;
    z-index: 2;
    white-space: pre-line;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: rgba($white_color, 0.7);
    font-weight: 500;
    margin: 0 26px 28px 0;
    z-index: 2;
    white-space: pre-wrap;
  }

  .button {
    z-index: 2;
    align-self: center;
    cursor: pointer;
    height: 52px;
    min-height: 52px;
  }
}
