@import 'styles/colors';

.infoContainer {
  font-size: 16px;
  line-height: 24px;
  color: rgba($dark_color, 0.6);

  .accent {
    font-weight: 700;
    color: rgba($dark_color, 1);
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 10px;
  color: $dark_color;
  line-height: 32px;
}
