@import 'styles/colors';
@import 'styles/variables';

.container {
  padding: 24px 20px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  max-width: $max-content-width;
  align-self: center;
  justify-content: center;

  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba($white_color, 0.8);
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .header {
    margin-bottom: 0;
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.6px;
  }

  .recurlyPowered {
    margin-top: 5px;
    align-self: flex-end;
    width: 110px;
  }

  button {
    margin-top: 32px;
  }

  .note {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: rgba($white_color, 0.6);
    margin: 10px 0 15px;
  }
}
