@import 'styles/colors';
@import 'styles/mixins';

.priceContainer {
  display: flex;
  flex-direction: column;
  height: min-content;
  position: relative;

  &.active {
    .price {
      border: 3px solid $main_accent_color;
    }

    .label {
      color: $main_accent_color;
    }
  }

  .price {
    @include noSelect;
    background: $light_cream_color;
    border: 3px solid $light_cream_color;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: rgba($dark_color, 0.64);
    flex: 1 0 auto;
    cursor: pointer;
    width: initial;
    max-width: initial;
    transition: border 0.1s ease;
    position: relative;
    box-sizing: content-box;
    margin-bottom: 10px;
    max-height: inherit;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:focus {
      outline: none;
    }
  }

  .label {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: rgba($dark_color, 0.64);
    transition: color 0.1s ease;
  }
}
