@import 'styles/variables';
@import 'styles/colors';

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1 1;

  .button {
    display: block;
  }

  .labelsContainer {
    display: flex;
    gap: 20px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }

  .imageWrapper {
    position: relative;
    align-self: center;

    .image {
      width: 100%;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: $max-content-width;
    align-self: center;
    flex: 1 1 auto;
    padding: 0 20px;

    .title {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      margin-bottom: 12px;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      max-width: $max-content-width;
      color: rgba($dark_color, 0.64);
      margin-bottom: 24px;
      padding: 0 11px;
    }

    .button {
      height: 52px;
      min-height: 52px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .container {
    .content {
      .title {
        white-space: pre-wrap;
      }
    }
  }
}

@media (min-width: 850px) {
  .container {
    .imageWrapper {
      .image {
        max-width: 600px;
      }
    }
  }
}
