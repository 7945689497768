@import 'styles/colors';

.container {
  position: relative;
  display: flex;

  &.dark {
    input {
      &::placeholder {
        color: rgba($white_color, 0.3);
      }
    }
  }

  input {
    flex: 1;
    padding: 0 60px 0 20px;
  }

  .eye {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;

    &.opened {
      padding: 0 23px;
    }

    &.closed {
      padding: 0 20px;
    }
  }
}
