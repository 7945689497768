.note {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: var(--main-color);
  margin: 23px 0 15px;

  .light {
    opacity: 0.5;
  }
}
