@import 'styles/colors';
@import 'styles/variables';

.review {
  width: calc(100% - 40px);
  position: relative;
  background: $light_cream_color;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  margin: -100px auto 0 auto;

  .userInfo {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .reviewText {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 16px;
    letter-spacing: 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .date {
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      margin-right: 10px;
      color: rgba($dark_color, 0.64);
    }

    .icons {
      display: flex;
      gap: 6px;

      svg {
        align-self: center;
        justify-self: center;
      }
    }
  }
}

@media (max-width: 375px) {
  .review {
    margin-bottom: 20px;
  }
}

@media (max-width: 365px) {
  .review {
    padding: 12px;
    margin-top: -80px;
    margin-bottom: 20px;

    .userInfo {
      font-size: 14px;
      line-height: 20px;
    }

    .reviewText {
      font-size: 13px;
    }

    .footer {
      .date {
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 350px) {
  .review {
    .reviewText {
      font-size: 10px;
      line-height: 16px;
    }
  }
}
