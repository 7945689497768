$dark_color: #090110;
$light_cream_color: #f4f1e8;
$active_green_color: #6dbc81;
$white_color: #ffffff;
$main_accent_color: #802df6;
$secondary_accent_color: #b250ff;
$light_violet_color: #e9dff2;
$grey_color: #807b7b;
$dark_purple_color: #0b0216;
$accent_red: #ef5350;

$gradient: linear-gradient(
  270deg,
  $secondary_accent_color 0%,
  $main_accent_color 100%
);
