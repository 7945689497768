@import 'styles/variables';
@import 'styles/colors';

.paymentContainer {
  color: white;
  background-color: $dark_purple_color;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.header {
  margin-bottom: 0;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.6px;
}

button {
  margin-top: 20px;
  max-width: 100%;
}

.paymentFormContainer {
  padding: 20px;
  max-width: 530px;
  display: flex;
  flex-direction: column;

  .logo {
    margin-bottom: 36px;
    align-self: center;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 11px;
    color: white;
  }
}

.recurlyPowered {
  width: 111px;
  margin-top: 10px;
  float: right;
  align-self: flex-end;
}

@media (min-width: $breakpoint-tablet) {
  .paymentFormContainer {
    padding: 0;
    justify-content: center;

    .logo {
      width: 90px;
      height: 30px;
      align-self: center;
      margin-bottom: 50px;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    flex: 1;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
