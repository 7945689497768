@import 'styles/variables';
@import 'styles/colors';

.bodyBackground {
  background-color: $dark_color;
}

.title {
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: -0.2px;
  margin: 0;
  color: white;
  text-align: center;
}

.subTitle {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 12px 20px 0;
}

.note {
  font-size: 12px;
  line-height: 16px;
  margin: 10px 20px 0;
  text-align: center;
  color: rgba($white_color, 0.6);
}

.description {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: rgba($white_color, 0.6);
  margin: 11px 20px 10px;
  text-align: center;
}

.contentContainer {
  width: 100%;
  padding: 0;
  background-color: #110021;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .phoneBackgroundContainer {
    flex: 1;
    width: 100%;
    position: relative;
    background-color: $dark_color;
    margin-bottom: -10px;

    .phoneBackground {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      position: absolute;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 100% auto;

      @media (max-height: 640px) {
        background-position: bottom -50px center;
      }

      @media (max-height: 600px) {
        background-position: bottom -105px center;
      }
    }

    .logo {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 58px;
      height: 20px;
    }

    .gradient {
      background: linear-gradient(180deg, #110021 0%, rgba(11, 2, 22, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;

      @media (max-height: 640px) {
        height: 100px;
      }

      @media (max-height: 600px) {
        height: 50px;
      }
    }
  }

  .textWrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
    white-space: pre-wrap;

    .topSpace {
      height: 20px;
    }

    button {
      margin: 10px 20px;
      width: inherit;
      max-width: inherit;
      height: 49px;
    }

    a {
      margin: 10px 20px;
      width: inherit;
      max-width: inherit;
      background-image: linear-gradient(
        to right,
        $main_accent_color 0%,
        $secondary_accent_color 100%
      );
      border: none;
      border-radius: 50px;
      color: white;
      text-align: center;
      font-size: 16px;
      text-decoration: none;
      display: inline-block;
      font-weight: 600;
      outline: none;
      padding: 14px 0 16px;
      font-family: 'Euclid Circular A';
    }
  }

  .button {
    display: block;
  }
}

@media (min-width: $breakpoint-tablet) {
  .contentContainer {
    margin-top: 0;
    margin-bottom: 0;
    justify-content: flex-start;

    .phoneBackgroundContainer {
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      position: relative;
      flex: 3;
      margin-bottom: 0;

      .logo {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

      .phoneBackground {
        opacity: 0;
        max-width: 100%;
      }
    }

    .textWrapper {
      flex: 2;
      margin-top: -50px;
      z-index: 2;
      max-width: initial;
      align-items: center;
      margin-bottom: 0;

      .title {
        font-size: 48px;
      }

      .description {
        margin-top: 12px;
      }

      button {
        margin-left: 0;
        margin-right: 0;
        width: 250px;
        padding-left: 45px;
        padding-right: 45px;
      }

      a {
        padding: 14px 42px 16px;
      }
    }
  }
}
