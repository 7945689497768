@import 'styles/colors';

.disclaimerInfo {
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba($dark_color, 0.5);
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
}
