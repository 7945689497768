@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  align-items: flex-start;
  padding: 0 0 10px 0;
  margin: 16px 0px 4px;
  border-radius: 16px;
  max-width: 480px;
  color: rgba($dark_color, 0.6);

  img {
    max-width: 45%;
    margin-left: -40px;
  }

  .check {
    min-width: 16px;
    height: 16px;

    path {
      fill: #802df6;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-right: 20px;

    ul {
      list-style-type: none;
      padding: 0;
      margin-top: 0;

      li {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-top: 12px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .titleWrapper {
      display: flex;
      justify-content: space-between;
      color: $dark_color;

      .title {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
        font-weight: 700;
      }

      .price {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2;
        font-weight: 600;
      }
    }

    .subtitle {
      font-size: 14px;
      line-height: 18px;
      color: $dark_color;
      margin-top: 6px;
    }

    .label {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      width: 73px;
      height: 26px;
      border-radius: 28px;
      background: $gradient;
      color: $white_color;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .container {
    img {
      margin-left: -25px;
    }
  }
}
